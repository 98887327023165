<template>
  <b-container fluid>
    <b-modal v-model="confirmModal" title="Confirmación" size="sm" centered>
      <p>Está seguro que desea guardar este {{ this.message }}?</p>
      <template #modal-footer>
        <b-button variant="danger" size="sm" class="float-right" @click="confirmModal=false">
          No
        </b-button>
        <b-button variant="success" size="sm" class="float-right" @click="guardarAsamblea">
          Sí
        </b-button>
      </template>
    </b-modal>
    <b-modal v-model="confirmServiceModal" title="Confirmación" size="sm" centered @close="clearConfirmServiceModal">
      <p>¿Está seguro que desea aprobar el servicio del operario {{ this.currentService.name}}?</p>
      <ul>
        <li><span class="font-weight-bold">Hora de inicio: </span>{{ this.currentService.startTime }}</li>
        <li><span class="font-weight-bold">Hora de fin: </span>{{ this.currentService.endTime }}</li>
        <li><span class="font-weight-bold">Total de horas: </span>{{ this.currentService.totalHours }}</li>
        <li><span class="font-weight-bold">Valor a pagar: </span>${{ this.currentService.totalValue }}</li>
      </ul>
      <template #modal-footer>
        <b-button variant="danger" size="sm" class="float-right" @click="clearConfirmServiceModal">
          No
        </b-button>
        <b-button variant="success" size="sm" class="float-right" @click="confirmService">
          Sí
        </b-button>
      </template>
    </b-modal>
    <b-modal v-model="modalShow" :title="'Pedidos Asambleas # ' + this.datosAsambleas1.id" size="xl" no-close-on-backdrop @close="clear()">
      <b-overlay :show="overlay" rounded="sm">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <b-row>
              <b-col lg="12">
                <iq-card body-class="p-0">
                  <template v-slot:body>
                    <div class="iq-edit-list">
                      <tab-nav :pills="true" class="iq-edit-profile d-flex pills-assemblies">
                        <tab-nav-items class="col-md-3 p-0" :active="true" href="#client-information" title="Datos del Cliente" iconClass="fas fa-building mr-1" />
                        <tab-nav-items class="col-md-3 p-0" :active="false" href="#billing" title="Cotizaciones y pagos" iconClass="fas fa-money-bill mr-1" />
                        <tab-nav-items class="col-md-3 p-0" :active="false" href="#logistic" title="Logística" iconClass="fas fa-dolly mr-1" />
                        <tab-nav-items class="col-md-3 p-0" :active="false" href="#others" title="Personal" @click="getStaffIndex()" iconClass="fas fa-users mr-1"  />
                      </tab-nav>
                    </div>
                  </template>
                </iq-card>
              </b-col>
              <b-col lg="12">
                <div class="iq-edit-list-data">
                  <tab-content id="pills-tabContent-2">
                    <tab-content-item :active="true" id="client-information" >
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">Datos del Cliente</h4>
                        </template>
                        <template v-slot:body>
                          <!-- CAMPO Y BOTON PARA BUSCAR POR NIT  -->
                          <div>
                            <b-row>
                              <b-col col lg="6" sm="12" class="mb-3">
                                <ValidationObserver v-slot="{ handleSubmit }" ref="observerFilter">
                                  <form @submit.prevent="handleSubmit(filterClient(contactData.identificationObjectNumber))">
                                    <label class="ml-2" for="input-small">Buscar por NIT</label>
                                    <ValidationProvider name="Valor de" v-slot="{ errors }" >
                                      <b-input-group size="sm">
                                        <template #prepend>
                                          <b-input-group-text >
                                            <i class="ri-bookmark-2-line"></i>
                                          </b-input-group-text>
                                        </template>
                                        <b-form-input v-model="contactData.identificationObjectNumber" type="number" id="nitSearchFormControl" placeholder="Ingrese NIT sin digito de verficación" :class="(errors.length > 0 ? ' is-invalid' : '')">
                                        </b-form-input>
                                        <b-button @click="clearBuscar()" variant="primary" text class="ml-3" type="submit">
                                          Buscar
                                          <i class="ri-search-eye-line ml-2" ></i>
                                        </b-button>
                                        <div class="invalid-feedback ml-2">
                                            <span>{{ errors[0] }}</span>
                                        </div>
                                      </b-input-group>
                                      </ValidationProvider>
                                    </form>
                                </ValidationObserver>
                              </b-col>
                              <b-col col lg="6" sm="12" class="mb-3">
                                <!-- INICIO DE CAMPO BUSCAR POR NOMBRE -->
                                <label class="ml-2" for="input-small">Buscar por Nombre</label>
                                <div class="row">
                                  <div class="search-wrapper panel-heading col-sm-12">
                                    <input @click="getNameIndex()" class="form-control" type="text" v-model="searchQueryName" placeholder="Buscar por Nombre" />
                                  </div>
                                </div>
                                <div class="table-responsive">
                                  <table v-if="resourcesName.length" class="table">
                                    <tbody>
                                      <tr v-for="item in resultQueryName.slice(0, 5)" :key="item.id">
                                        <td><a v-bind:href="item.uri" @click="agregarName(item), filterClient(item.nit)">{{item.name}}</a></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              <!-- FIN DE CAMPO BUSCAR POR NOMBRE -->
                              </b-col>
                            </b-row>
                          </div>
                          <!-- FIN DE CAMPO Y BOTON PARA BUSCAR POR NIT -->
                          <ValidationObserver v-slot="{ handleSubmit }" ref="observerContact">
                            <b-form @submit.prevent="handleSubmit(confirmModal)">
                              <b-row>
                                <b-col v-for="input in inputs1" :key="input.index" cols="12" sm="6" class="mb-3">
                                  <label class="ml-2" for="input-small">{{input.name}}</label>
                                  <!-- Si es texto o numero -->
                                  <ValidationProvider :name="input.name" :rules="input.rules" v-slot="{ errors }">
                                    <b-input-group v-if="input.type === 'text' || input.type === 'number'" size="sm">
                                      <template #prepend>
                                        <b-input-group-text >
                                          <i :class="input.iconClass"></i>
                                        </b-input-group-text>
                                      </template>
                                      <b-form-input :id="input.name" v-model="contactData[input.dataModel]" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                                      <div class="invalid-feedback ml-2">
                                        <span>{{ errors[0] }}</span>
                                    </div>
                                    </b-input-group>
                                  </ValidationProvider>
                                  <!-- Si es select -->
                                  <b-input-group v-if="input.type === 'select'" size="sm">
                                    <template #prepend>
                                      <b-input-group-text >
                                        <i class="ri-bookmark-2-line"></i>
                                      </b-input-group-text>
                                    </template>
                                    <b-form-select
                                      :options="input.options" type="text" id="docTypeFormControl" v-model="contactData[input.dataModel]">
                                    </b-form-select>
                                  </b-input-group>
                                </b-col>
                                <b-col cols="12">
                                  <div class="d-flex justify-content-end">
                                    <b-button v-if="nitFound" @click.prevent="updateDataClient" variant="primary" class="mr-2">Actualizar cliente</b-button>
                                    <b-button @click.prevent="next(1)">Siguiente</b-button>
                                  </div>
                                </b-col>
                              </b-row>
                            </b-form>
                          </ValidationObserver>
                        </template>
                      </iq-card>
                    </tab-content-item>
                    <tab-content-item :active="false" id="billing">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">Cotizaciones, pagos y anticipos</h4>
                        </template>
                        <template v-slot:body>
                          <p>Numero de Cotizacion: {{ datosAsambleas1.cotizacion }}  / /  ID de Cotizacion: {{ datosAsambleas1.idCotizacion }}</p>
                          <p>Cotización</p>
                          <input v-model="datosAsambleas1.cotizacion " label="cot" type="text" size="sm">
                          <p>ID Cotización</p>
                          <input v-model="datosAsambleas1.idCotizacion" type="text" size="sm">
                              <p>Fecha Pedido: {{ datosAsambleas1.fechaPedido }}  / /  Fecha Vencimiento: {{ datosAsambleas1.fechaVencimiento }}</p>
                              <p>Estado de la Cotizacion: {{ estadoCotizacion1 }}
                                <i v-if="!cotizacion1" class="ri-arrow-drop-down-line ml-3">
                                  <b-button class="mt-3" @click="aprobarCotizacion">Aprobar Cotizacion</b-button>
                                </i>
                              </p>
                              <ValidationObserver v-slot="{ handleSubmit }" ref="observerContact1">
                                <b-form @submit.prevent="handleSubmit(confirmModal)">
                                    <b-container fluid>
                                      <b-row>
                                        <b-col md="12">
                                          <iq-card>
                                            <template v-slot:headerTitle>
                                            </template>
                                            <template v-slot:footer>
                                              <b-row>
                                                <b-col cols="6">
                                                  <b-button variant="primary" @click="add">Add New</b-button>
                                                </b-col>
                                                <b-col cols="6" class="text-right">
                                                  <b-row class="mr-1" align-h="end"><p class="mr-2 font-weight-bold">Subtotal: </p>{{ currencyFormatterCop(subTotal1) }}</b-row>
                                                  <b-row class="mr-1" align-h="end"><p class="mr-2 font-weight-bold">Descuento: -</p>{{ currencyFormatterCop(discount) }}</b-row>
                                                  <b-row class="mr-1" align-h="end"><p class="mr-2 font-weight-bold">Subtotal: </p>{{ currencyFormatterCop(subTotal) }}</b-row>
                                                  <b-row class="mr-1" align-h="end"><p class="mr-2 font-weight-bold">IVA: </p>{{ currencyFormatterCop(iva) }}</b-row>
                                                  <b-row class="mr-1" align-h="end"><p class="mr-2 font-weight-bold">TOTAL: </p>{{ currencyFormatterCop(total) }}</b-row>
                                                </b-col>
                                              </b-row>
                                              <div>
                                              <div class="d-flex justify-content-end">
                                                <i v-if="cotizacion1" class="ri-arrow-drop-down-line ml-3">
                                                  <b-button class="mt-3" @click="saveDealRemision(true)">Guardar Nueva Cotizacion</b-button>
                                                </i>
                                                <i v-else class="ri-arrow-drop-up-line ml-3">
                                                  <p>
                                                    <b-form-input id="emailcorreo" type="email" v-model="emailCorreo"></b-form-input>
                                                    <b-button class="mt-3" @click="cotizacionCorreo">Enviar Cotizacion por Correo</b-button></p>
                                                  <p>
                                                    <b-button class="mt-3" @click="saveDealRemision(false)">Guardar Cotizacion Editada</b-button>
                                                  </p>
                                                </i>
                                              </div>
                                            </div>
                                            </template>
                                            <template v-slot:body>
                                              <b-row>
                                                <b-col md="12" class="table-responsive">
                                                  <b-table bordered hover :items="rows" :fields="columns">
                                                    <template v-slot:cell(name)="data">
                                                      <span v-if="!data.item.editable">{{ data.item.name }}</span>
                                                      <div v-if="data.item.editable" class="panel panel-default">
                                                        <div class="row">
                                                          <div class="search-wrapper panel-heading col-sm-12">
                                                            <input class="form-control" type="text" v-model="searchQuery" placeholder="Search" />
                                                          </div>
                                                        </div>
                                                        <div class="table-responsive">
                                                          <table v-if="resources.length" class="table">
                                                            <tbody>
                                                              <tr v-for="item in resultQuery.slice(0, 5)" :key="item.index">
                                                                <td><a v-bind:href="item.uri" @click="agregar(item)">{{item.name}}</a></td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </div>
                                                      </div>
                                                    </template>
                                                    <template v-slot:cell(discount)="data">
                                                      <span v-if="!data.item.editable">{{ data.item.discount }}</span>
                                                      <input type="text" v-model="data.item.discount" v-else class="form-control">
                                                    </template>
                                                    <template v-slot:cell(price)="data">
                                                      <span v-if="!data.item.editable">{{ (data.item.price) }}</span>
                                                      <input type="text" v-model="data.item.price" v-else class="form-control">
                                                    </template>
                                                    <template v-slot:cell(tax)="data">
                                                      <span v-if="!data.item.editable">{{ data.item.tax }}</span>
                                                      <input type="number" v-model="data.item.tax" v-else class="form-control">
                                                    </template>
                                                    <template v-slot:cell(description)="data">
                                                      <span v-if="!data.item.editable">{{ data.item.description }}</span>
                                                      <input type="text" v-model="data.item.description" v-else class="form-control">
                                                    </template>
                                                    <template v-slot:cell(quantity)="data">
                                                      <span v-if="!data.item.editable">{{ data.item.quantity }}</span>
                                                      <input type="number" v-model="data.item.quantity" v-else class="form-control">
                                                    </template>
                                                    <template v-slot:cell(total)="data">
                                                      <span v-if="!data.item.editable">{{ data.item.total }}</span>
                                                      <input type="text" v-model="getTotal" v-else class="form-control">
                                                    </template>
                                                    <template v-slot:cell(action)="data">
                                                      <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                                      <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="submit(data.item)" v-else>Ok</b-button>
                                                      <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                                                    </template>
                                                  </b-table>
                                                </b-col>
                                              </b-row>
                                            </template>
                                          </iq-card>
                                        </b-col>
                                      </b-row>
                                    </b-container>
                                </b-form>
                              </ValidationObserver>
                          <ValidationObserver v-slot="{ handleSubmit }" ref="observerContact2">
                            <b-form @submit.prevent="handleSubmit(confirmModal)">
                              <b-row>
                                <b-col v-for="input in inputs2" :key="input.dataModel" cols="12" sm="6" class="mb-3">
                                  <label class="ml-2" for="input-small">{{input.name}}</label>
                                  <!-- Si es texto o numero -->
                                  <ValidationProvider :name="input.name" :rules="input.rules" v-slot="{ errors }">
                                    <b-input-group v-if="input.type === 'text' || input.type === 'number'" size="sm">
                                      <template #prepend>
                                        <b-input-group-text >
                                          <i :class="input.iconClass"></i>
                                        </b-input-group-text>
                                      </template>
                                      <b-form-input :id="input.name" v-model="orderData[input.dataModel]" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                                      <div class="invalid-feedback ml-2">
                                        <span>{{ errors[0] }}</span>
                                    </div>
                                    </b-input-group>
                                  </ValidationProvider>
                                  <!-- Si es select -->
                                  <b-input-group v-if="input.type === 'select'" size="sm">
                                    <template #prepend>
                                      <b-input-group-text >
                                        <i class="ri-bookmark-2-line"></i>
                                      </b-input-group-text>
                                    </template>
                                    <b-form-select
                                      :options="input.options" type="text" id="docTypeFormControl" v-model="orderData[input.dataModel]">
                                    </b-form-select>
                                  </b-input-group>
                                </b-col>
                                <!-- <b-table :items="sale.items" ></b-table> -->
                              </b-row>
                            </b-form>
                          </ValidationObserver>
                          <div class="d-flex justify-content-end">
                            <b-button class="mt-3" @click.prevent="next(2)">Siguiente</b-button>
                          </div>
                        </template>
                      </iq-card>
                    </tab-content-item>
                    <tab-content-item :active="false" id="logistic">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">Logistica</h4>
                        </template>
                        <template v-slot:body>
                          <ValidationObserver v-slot="{ handleSubmit }" ref="observerContact4">
                            <b-form @submit.prevent="handleSubmit(confirmModal)">
                              <b-row>
                                <b-col v-for="input in inputs3" :key="input.index" cols="12" sm="6" class="mb-3">
                                  <label class="ml-2" for="input-small">{{input.name}}</label>
                                  <!-- Si es texto o numero -->
                                  <ValidationProvider :name="input.name" :rules="input.rules" v-slot="{ errors }">
                                    <b-input-group v-if="input.type === 'text' || input.type === 'number'" size="sm">
                                      <template #prepend>
                                        <b-input-group-text >
                                          <i :class="input.iconClass"></i>
                                        </b-input-group-text>
                                      </template>
                                      <b-form-input :id="input.name" v-model="logisticData[input.dataModel]" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                                      <div class="invalid-feedback ml-2">
                                        <span>{{ errors[0] }}</span>
                                    </div>
                                    </b-input-group>
                                  </ValidationProvider>
                                  <!-- Si es select -->
                                  <b-input-group v-if="input.type === 'select'" size="sm">
                                    <template #prepend>
                                      <b-input-group-text >
                                        <i class="ri-bookmark-2-line"></i>
                                      </b-input-group-text>
                                    </template>
                                    <b-form-select
                                      :options="input.options" type="text" id="docTypeFormControl" v-model="logisticData[input.dataModel]">
                                    </b-form-select>
                                  </b-input-group>
                                  <!-- Si es select -->
                                  <b-input-group v-if="input.type === 'calendar'" size="sm">
                                    <template #prepend>
                                      <b-input-group-text >
                                        <i class="ri-bookmark-2-line"></i>
                                      </b-input-group-text>
                                    </template>
                                    <b-form-datepicker
                                      type="text" id="docTypeFormControl1" v-model="logisticData[input.dataModel]">
                                    </b-form-datepicker>
                                  </b-input-group>
                                  <!-- si es hora-->
                                  <b-form-group v-if="input.type === 'hour'" size="sm">
                                    <template #prepend>
                                      <b-input-group-text >
                                        <i class="ri-bookmark-2-line"></i>
                                      </b-input-group-text>
                                    </template>
                                    <b-time v-model="logisticData[input.dataModel]" id="hour" locale="en" class="mb-2"></b-time>
                                    {{input.dataModel}}
                                  </b-form-group>
                                </b-col>
                                <!-- <b-table :items="sale.items" ></b-table> -->
                              </b-row>
                            </b-form>
                          </ValidationObserver>
                          <!-- BOTONES PARA CARGAR BASE DE DATOS, CREAR LA SALA Y CREAR EL USUARIO EN ZOOM -->
                          <b-button variant="secondary" class="mb-3 mr-1" @click="createSala">Crear Sala</b-button>
                          <b-button variant="primary" class="mb-3 mr-1" @click="showModal">Subir Base de Datos</b-button>
                          <b-button variant="success" @click.prevent="createMeeting({type: 1, data: false})">Crear Reunion en Zoom</b-button>
                          <modal-meeting :queryDataZoom="datosAsambleas1" :type="type" @dataZoom="dataZoom" @showAlertMessage="showAlertMessage"  @changeModal="changeModal" :showModal="showModal2" :meetingUpdate="meetingUpdate"></modal-meeting>
                          <!-- MODAL PARA LA CARGA DE LA BASE DE DATOS -->
                          <template>
                            <b-modal no-close-on-backdrop ref="my-modal" size="xl" @hide="closeMyModalAnCleanData" hide-footer title="Addd a property">
                                <div>
                                  <div class="card-style">
                                    <b-progress :value="progress" variant="success"></b-progress>
                                  </div>
                                  <b-card v-if="current_step==1" class="card-style" title="DB">
                                    <b-card-text>Add DB</b-card-text>
                                    <template>
                                      <b-container fluid>
                                        <b-row>
                                          <b-col sm="12">
                                              <iq-card>
                                                <template v-slot:headerTitle>
                                                  <h4 class="card-title">Importation</h4>
                                                </template>
                                                <template v-slot:body>
                                                  <div class="app-container">
                                                    <upload-excel-component v-if="hideExcel" class="mb-3" :on-success="handleSuccess" :before-upload="beforeUpload" />
                                                    <user-load @hideExcelField="hideExcelField" @saveDataMap="saveDataMap" :headers="tableHeader" :dataUsers="tableData"></user-load>
                                                  </div>
                                                </template>
                                              </iq-card>
                                              <template #overlay>
                                                <div class="text-center">
                                                  <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
                                                  <p id="cancel-label">Please wait...</p>
                                                </div>
                                              </template>
                                          </b-col>
                                        </b-row>
                                      </b-container>
                                    </template>
                                    <b-button class="float-right" variant="secondary" @click="onClickNext(2)" :disabled="disabledBotonNext">Next</b-button>
                                  </b-card>
                                  <b-card v-if="current_step==2" class="card-style" title="Properties">
                                    <div v-if="!spinner">
                                      <b-card-text >Add properties</b-card-text>
                                      <ValidationObserver v-slot="{handleSubmit}">
                                        <form @submit.prevent="handleSubmit(handleOk1)">
                                        <b-form-group id="input-group-1" label="Una vez cargue el excel este proceso no lo podra realizar nuevamente, la informacion que esta ingresando sera oficialmente toda la informacion de la copropiedad con su respectivos propietarios y propiedades. " label-for="text2-input">
                                            <ValidationProvider name="Message the confirm " rules="required" v-slot="{ errors }">
                                              <b-form-input id="text2-input" type="text" v-model="properties.confirmation" placeholder="Ingrese la palabra CONFIRMO para aceptar el cargue de información. " :class="(errors.length > 0 ? 'is-invalid':'')"></b-form-input>
                                              <div class="invalid-feedback">
                                                <span>{{ errors[0] }}</span>
                                              </div>
                                            </ValidationProvider>
                                        </b-form-group>
                                          <b-button class="float-left" variant="primary" @click="onClickBack">Back</b-button>
                                          <!-- <b-button class="float-right" variant="primary" @click="consulta">consulta</b-button> -->
                                          <b-button class="float-right" variant="secondary" type="submit">Submit</b-button>
                                        </form>
                                      </ValidationObserver>
                                    </div>
                                    <div v-else>
                                      <div class="">
                                        <b-row>
                                          <b-col cols="12">
                                            <b-progress :value="value" :max="max" :variant="colorVariant" show-progress animated></b-progress>
                                          </b-col>
                                        </b-row>
                                      </div>
                                      <div>
                                        <loader-process :message="message1"></loader-process>
                                      </div>
                                    </div>
                                  </b-card>
                                </div>
                            </b-modal>
                          </template>
                          <div class="d-flex justify-content-end">
                            <b-button class="mt-3" @click.prevent="next(3)">Siguiente</b-button>
                          </div>
                        </template>
                      </iq-card>
                    </tab-content-item>
                    <tab-content-item :active="false" id="others">
                      <iq-card>
                        <template v-slot:body>
                          <b-row pill class="mb-5 text-center bg-secondary head-row">
                            <b-col col class="text-white">
                              <h6 class="text-white">Hora de inicio</h6>
                              <span>{{ formatAMPM(datosAsambleas1.hour) }}</span>
                            </b-col>
                            <b-col col class="text-white">
                              <h6 class="text-white">Hora de fin</h6>
                              <span v-if="datosAsambleas1.endHour !== '-'" >{{ formatAMPM(datosAsambleas1.endHour) }}</span>
                              <span v-else >Sin definir</span>
                              <i class="fas fa-edit fa-xs hoverSpan ml-1" @click="editEndTime=!editEndTime"></i>
                            </b-col>
                            <b-col col class="text-white">
                              <h6 class="text-white">Total de horas</h6>
                              <span v-if="datosAsambleas1.endHour !== '-'" >{{ totalHours + 1 }}</span>
                              <span v-else >Sin definir</span>
                            </b-col>
                            <b-col col class="text-white">
                              <h6 class="text-white">Horas extra</h6>
                              <span v-if="datosAsambleas1.endHour !== '-' && (totalHours - 5) > 0" >{{ totalHours - 5 }}</span>
                              <span v-else >0</span>
                            </b-col>
                          </b-row>
                          <b-row v-if="editEndTime" class="mt-2 mb-4" align-h="end">
                            <b-col>
                              <b-time v-model="logisticData.horaFin" locale="en"></b-time>
                              <span class="ml-2 text-success hoverSpan" @click="checkHours">OK</span>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="text-center mydiv">
                              <i class="fas fa-volume-up fa-lg"></i>
                              <b-form-checkbox v-model="otherData.hasSonido" id="checkbox-1" name="checkbox-1" :value="true" :unchecked-value="false">
                                Sonido
                              </b-form-checkbox>
                            </b-col>
                            <b-col class="text-center">
                              <i class="fas fa-file-signature fa-lg"></i>
                              <b-form-checkbox  v-model="otherData.hasRegistro" id="checkbox-2" name="checkbox-3" :value="true" :unchecked-value="false">
                                Registro
                              </b-form-checkbox>
                            </b-col>
                            <b-col class="text-center">
                              <i class="fas fa-chalkboard-teacher fa-lg"></i>
                              <b-form-checkbox v-model="otherData.hasVirtual" id="checkbox-3" name="checkbox-3" :value="true" :unchecked-value="false">
                                Virtual
                              </b-form-checkbox>
                            </b-col>
                            <b-col class="text-center mydiv">
                              <i class="fas fa-plug fa-lg"></i>
                              <b-form-checkbox v-model="otherData.hasConexion" id="checkbox-4" name="checkbox-4" :value="true" :unchecked-value="false">
                                Conexión
                              </b-form-checkbox>
                            </b-col>
                            <b-col class="text-center">
                              <i class="fas fa-file-word fa-lg"></i>
                              <b-form-checkbox v-model="otherData.hasActa" id="checkbox-5" name="checkbox-5" :value="true" :unchecked-value="false">
                                Acta
                              </b-form-checkbox>
                            </b-col>
                            <b-col class="text-center">
                              <i class="fas fa-car fa-lg"></i>
                              <b-form-checkbox v-model="otherData.hasConductor" id="checkbox-6" name="checkbox-6" :value="true" :unchecked-value="false">
                                Conductor
                              </b-form-checkbox>
                            </b-col>
                          </b-row>
                          <hr>
                          <b-button class="mt-2 mb-5" variant="primary" @click="getStaffIndex(), operariosDisponibles = true">Consultar Disponibilidad</b-button>
                          <b-row v-if="otherData.hasSonido && otherData.hasSonido !== '-'">
                            <b-col cols="6" sm="3" class="mb-3">
                              <h6>Operarios de Sonido ({{sonidoData.length}})</h6>
                            </b-col>
                            <b-col cols="6" sm="2" class="mb-3">
                              <b-input-group size="sm">
                                <template #prepend>
                                  <b-input-group-text >
                                    <i class="ri-bookmark-2-line"></i>
                                  </b-input-group-text>
                                </template>
                                <b-form-input type="number" v-model="otherData.sonido1"></b-form-input>
                              </b-input-group>
                            </b-col>
                            <b-col cols="12" sm="5" class="mb-3">
                              <b-form-select v-if="otherData.sonido1 > 0" v-model="operarioSonido" :options="sonidoData" size="sm"></b-form-select>
                            </b-col>
                            <b-col cols="12" sm="2" class="mb-3">
                              <b-button v-if="operarioSonidoSeleccionado.length < otherData.sonido1" @click="setProvider('operarioSonido', 'sonidoData')" variant="success">Agregar</b-button>
                              <b-button v-if="operarioSonidoSeleccionado.length !== 0" @click="deleteOperatorScheduleSingle('operarioSonido')" variant="danger">Borrar</b-button>
                            </b-col>
                            <b-col cols="12" sm="12" class="mb-3">
                              <ol>
                                <li v-for="(operario, index) in operarioSonidoSeleccionado" :key="index">
                                  <b-row>
                                    <b-col cols="12" sm="4" >
                                      <b-form-radio v-model="operarioSonidoIndex" :value="operario.docId">{{ operario.name }}</b-form-radio>
                                    </b-col>
                                    <b-col v-if="operario.startTime !== undefined && operario.startTime !== null" cols="6" sm="3" >
                                      <b-time v-if="operario.editHour" v-model="operarioSonidoSeleccionado[index].startTime" locale="en"></b-time>
                                      <span v-if="!operario.editHour" class="mr-1 font-weight-bold">Inicio:</span>
                                      <span v-if="!operario.editHour" class="mr-2">{{ formatAMPM(operario.startTime) }}</span>
                                      <span v-if="!operario.editHour" class="ml-2 text-warning hoverSpan" @click="operario.editHour=!operario.editHour">editar</span>
                                      <span v-else class="ml-2 text-success hoverSpan" @click="operario.editHour=!operario.editHour">OK</span>
                                    </b-col>
                                    <b-col cols="6" sm="3" >
                                      <b-time v-if="operario.editHour" v-model="operarioSonidoSeleccionado[index].endTime" locale="en"></b-time>
                                      <span v-if="!operario.editHour" class="mr-1 font-weight-bold">Fin:</span>
                                      <span v-if="!operario.editHour && operario.endTime !== '-'" class="mr-2">{{ formatAMPM(operario.endTime) }}</span>
                                      <span v-if="operario.endTime === '-'" class="mr-2">Sin asignar</span>
                                      <span v-if="!operario.editHour" class="ml-2 text-warning hoverSpan" @click="operario.editHour=!operario.editHour">editar</span>
                                      <span v-else class="ml-2 text-success hoverSpan" @click="operario.editHour=!operario.editHour">OK</span>
                                    </b-col>
                                    <b-col cols="12" sm="2" >
                                      <b-button v-if="operario.endTime !== '-' && !operario.approved && datosAsambleas1.endHour !== '-'" variant="success" @click="approveService(operario, 'sonido', 'operarioSonidoSeleccionado', index)">Aprobar</b-button>
                                      <span v-if="operario.endTime !== '-' && operario.approved" class="text-success" >Aprobado</span>
                                    </b-col>
                                  </b-row>
                                </li>
                              </ol>
                            </b-col>
                          </b-row>
                          <b-row v-if="otherData.hasRegistro && otherData.hasRegistro !== '-'">
                            <b-col cols="6" sm="3" class="mb-3">
                              <h6>Operarios Registro ({{registroData.length}})</h6>
                            </b-col>
                            <b-col cols="6" sm="2" class="mb-3">
                              <b-input-group size="sm">
                                <template #prepend>
                                  <b-input-group-text >
                                    <i class="ri-bookmark-2-line"></i>
                                  </b-input-group-text>
                                </template>
                                <b-form-input type="number" v-model="otherData.registro1"></b-form-input>
                              </b-input-group>
                            </b-col>
                            <b-col cols="12" sm="5" class="mb-3">
                              <b-form-select v-if="otherData.registro1 > 0" v-model="operarioRegistro" :options="registroData" size="sm"></b-form-select>
                            </b-col>
                            <b-col cols="12" sm="2" class="mb-3">
                              <b-button v-if="operarioRegistroSeleccionado.length < otherData.registro1" @click="setProvider('operarioRegistro', 'registroData')" variant="success">Agregar</b-button>
                              <b-button v-if="operarioRegistroSeleccionado.length !== 0" @click="deleteOperatorScheduleSingle('operarioRegistro')" variant="danger">Borrar</b-button>
                            </b-col>
                            <b-col cols="12" sm="12" class="mb-3">
                              <ol>
                                <li v-for="(operario, index) in operarioRegistroSeleccionado" :key="index">
                                  <b-row>
                                    <b-col cols="12" sm="4" >
                                      <b-form-radio v-model="operarioRegistroIndex" :value="operario.docId">{{ operario.name }}</b-form-radio>
                                    </b-col>
                                    <b-col v-if="operario.startTime !== undefined && operario.startTime !== null" cols="6" sm="3" >
                                      <b-time v-if="operario.editHour" v-model="operarioRegistroSeleccionado[index].startTime" locale="en"></b-time>
                                      <span v-if="!operario.editHour" class="mr-1 font-weight-bold">Inicio:</span>
                                      <span v-if="!operario.editHour" class="mr-2">{{ formatAMPM(operario.startTime) }}</span>
                                      <span v-if="!operario.editHour" class="ml-2 text-warning hoverSpan" @click="operario.editHour=!operario.editHour">editar</span>
                                      <span v-else class="ml-2 text-success hoverSpan" @click="operario.editHour=!operario.editHour">OK</span>
                                    </b-col>
                                    <b-col cols="6" sm="3" >
                                      <b-time v-if="operario.editHour" v-model="operarioRegistroSeleccionado[index].endTime" locale="en"></b-time>
                                      <span v-if="!operario.editHour" class="mr-1 font-weight-bold">Fin:</span>
                                      <span v-if="!operario.editHour && operario.endTime !== '-'" class="mr-2">{{ formatAMPM(operario.endTime) }}</span>
                                      <span v-if="operario.endTime === '-'" class="mr-2">Sin asignar</span>
                                      <span v-if="!operario.editHour" class="ml-2 text-warning hoverSpan" @click="operario.editHour=!operario.editHour">editar</span>
                                      <span v-else class="ml-2 text-success hoverSpan" @click="operario.editHour=!operario.editHour">OK</span>
                                    </b-col>
                                    <b-col cols="12" sm="2" >
                                      <b-button v-if="operario.endTime !== '-' && !operario.approved && datosAsambleas1.endHour !== '-'" variant="success" @click="approveService(operario, 'registro', 'operarioRegistroSeleccionado', index)">Aprobar</b-button>
                                      <span v-if="operario.endTime !== '-' && operario.approved" class="text-success" >Aprobado</span>
                                    </b-col>
                                  </b-row>
                                </li>
                              </ol>
                            </b-col>
                          </b-row>
                          <b-row v-if="otherData.hasVirtual && otherData.hasVirtual !== '-'">
                            <b-col cols="6" sm="3" class="mb-3">
                              <h6>Operario Virtual ({{virtualData.length}})</h6>
                            </b-col>
                            <b-col cols="6" sm="2" class="mb-3">
                              <b-input-group size="sm">
                                <template #prepend>
                                  <b-input-group-text >
                                    <i class="ri-bookmark-2-line"></i>
                                  </b-input-group-text>
                                </template>
                                <b-form-input type="number" v-model="otherData.virtual1"></b-form-input>
                              </b-input-group>
                            </b-col>
                            <b-col cols="12" sm="5" class="mb-3">
                              <b-form-select v-if="otherData.virtual1 > 0" v-model="operarioVirtual" :options="virtualData" size="sm"></b-form-select>
                            </b-col>
                            <b-col cols="12" sm="2" class="mb-3">
                              <b-button v-if="operarioVirtualSeleccionado.length < otherData.virtual1" @click="setProvider('operarioVirtual', 'virtualData')" variant="success">Agregar</b-button>
                              <b-button v-if="operarioVirtualSeleccionado.length !== 0" @click="deleteOperatorScheduleSingle('operarioVirtual')" variant="danger">Borrar</b-button>
                            </b-col>
                            <b-col cols="12" sm="12" class="mb-3">
                              <ol>
                                <li v-for="(operario, index) in operarioVirtualSeleccionado" :key="index">
                                  <b-row>
                                    <b-col cols="12" sm="4" >
                                      <b-form-radio v-model="operarioVirtualIndex" :value="operario.docId">{{ operario.name }}</b-form-radio>
                                    </b-col>
                                    <b-col v-if="operario.startTime !== undefined && operario.startTime !== null" cols="6" sm="3" >
                                      <b-time v-if="operario.editHour" v-model="operarioVirtualSeleccionado[index].startTime" locale="en"></b-time>
                                      <span v-if="!operario.editHour" class="mr-1 font-weight-bold">Inicio:</span>
                                      <span v-if="!operario.editHour" class="mr-2">{{ formatAMPM(operario.startTime) }}</span>
                                      <span v-if="!operario.editHour" class="ml-2 text-warning hoverSpan" @click="operario.editHour=!operario.editHour">editar</span>
                                      <span v-else class="ml-2 text-success hoverSpan" @click="operario.editHour=!operario.editHour">OK</span>
                                    </b-col>
                                    <b-col cols="6" sm="3" >
                                      <b-time v-if="operario.editHour" v-model="operarioVirtualSeleccionado[index].endTime" locale="en"></b-time>
                                      <span v-if="!operario.editHour" class="mr-1 font-weight-bold">Fin:</span>
                                      <span v-if="!operario.editHour && operario.endTime !== '-'" class="mr-2">{{ formatAMPM(operario.endTime) }}</span>
                                      <span v-if="operario.endTime === '-'" class="mr-2">Sin asignar</span>
                                      <span v-if="!operario.editHour" class="ml-2 text-warning hoverSpan" @click="operario.editHour=!operario.editHour">editar</span>
                                      <span v-else class="ml-2 text-success hoverSpan" @click="operario.editHour=!operario.editHour">OK</span>
                                    </b-col>
                                    <b-col cols="12" sm="2" >
                                      <b-button v-if="operario.endTime !== '-' && !operario.approved && datosAsambleas1.endHour !== '-'" variant="success" @click="approveService(operario, 'virtual', 'operarioVirtualSeleccionado', index)">Aprobar</b-button>
                                      <span v-if="operario.endTime !== '-' && operario.approved" class="text-success" >Aprobado</span>
                                    </b-col>
                                  </b-row>
                                </li>
                              </ol>
                            </b-col>
                          </b-row>
                          <b-row v-if="otherData.hasVirtual && otherData.hasVirtual !== '-'">
                            <b-col cols="6" sm="3" class="mb-3">
                              <h6>Operario Soporte ({{soporteData.length}})</h6>
                            </b-col>
                            <b-col cols="6" sm="2" class="mb-3">
                              <b-input-group size="sm">
                                <template #prepend>
                                  <b-input-group-text >
                                    <i class="ri-bookmark-2-line"></i>
                                  </b-input-group-text>
                                </template>
                                <b-form-input type="number" v-model="otherData.soporte1"></b-form-input>
                              </b-input-group>
                            </b-col>
                            <b-col cols="12" sm="5" class="mb-3">
                              <b-form-select v-if="otherData.soporte1 > 0" v-model="operarioSoporte" :options="soporteData" size="sm"></b-form-select>
                            </b-col>
                            <b-col cols="12" sm="2" class="mb-3">
                              <b-button v-if="operarioSoporteSeleccionado.length < otherData.soporte1" @click="setProvider('operarioSoporte', 'soporteData')" variant="success">Agregar</b-button>
                              <b-button v-if="operarioSoporteSeleccionado.length !== 0" @click="deleteOperatorScheduleSingle('operarioSoporte')" variant="danger">Borrar</b-button>
                            </b-col>
                            <b-col cols="12" sm="12" class="mb-3">
                              <ol>
                                <li v-for="(operario, index) in operarioSoporteSeleccionado" :key="index">
                                  <b-row>
                                    <b-col cols="12" sm="4" >
                                      <b-form-radio v-model="operarioSoporteIndex" :value="operario.docId">{{ operario.name }}</b-form-radio>
                                    </b-col>
                                    <b-col v-if="operario.startTime !== undefined && operario.startTime !== null" cols="6" sm="3" >
                                      <b-time v-if="operario.editHour" v-model="operarioSoporteSeleccionado[index].startTime" locale="en"></b-time>
                                      <span v-if="!operario.editHour" class="mr-1 font-weight-bold">Inicio:</span>
                                      <span v-if="!operario.editHour" class="mr-2">{{ formatAMPM(operario.startTime) }}</span>
                                      <span v-if="!operario.editHour" class="ml-2 text-warning hoverSpan" @click="operario.editHour=!operario.editHour">editar</span>
                                      <span v-else class="ml-2 text-success hoverSpan" @click="operario.editHour=!operario.editHour">OK</span>
                                    </b-col>
                                    <b-col cols="6" sm="3" >
                                      <b-time v-if="operario.editHour" v-model="operarioSoporteSeleccionado[index].endTime" locale="en"></b-time>
                                      <span v-if="!operario.editHour" class="mr-1 font-weight-bold">Fin:</span>
                                      <span v-if="!operario.editHour && operario.endTime !== '-'" class="mr-2">{{ formatAMPM(operario.endTime) }}</span>
                                      <span v-if="operario.endTime === '-'" class="mr-2">Sin asignar</span>
                                      <span v-if="!operario.editHour" class="ml-2 text-warning hoverSpan" @click="operario.editHour=!operario.editHour">editar</span>
                                      <span v-else class="ml-2 text-success hoverSpan" @click="operario.editHour=!operario.editHour">OK</span>
                                    </b-col>
                                    <b-col cols="12" sm="2" >
                                      <b-button v-if="operario.endTime !== '-' && !operario.approved && datosAsambleas1.endHour !== '-'" variant="success" @click="approveService(operario, 'soporte', 'operarioSoporteSeleccionado', index)">Aprobar</b-button>
                                      <span v-if="operario.endTime !== '-' && operario.approved" class="text-success" >Aprobado</span>
                                    </b-col>
                                  </b-row>
                                  <b-tooltip :show.sync="soporteShow" target="tooltip-button-1" placement="leftbottom" variant="warning">
                                    Este proveedor tiene asignado ({{ operario.totalServices }}) servicios y {{ operario.totalUsers }} usuarios.
                                  </b-tooltip>
                                </li>
                              </ol>
                            </b-col>
                          </b-row>
                          <b-row v-if="otherData.hasConexion && otherData.hasConexion !== '-'">
                            <b-col cols="6" sm="3" class="mb-3">
                              <h6>Operario Conexión ({{conexionData.length}})</h6>
                            </b-col>
                            <b-col cols="6" sm="2" class="mb-3">
                              <b-input-group size="sm">
                                <template #prepend>
                                  <b-input-group-text >
                                    <i class="ri-bookmark-2-line"></i>
                                  </b-input-group-text>
                                </template>
                                <b-form-input type="number" v-model="otherData.conexion1"></b-form-input>
                              </b-input-group>
                            </b-col>
                            <b-col cols="12" sm="5" class="mb-3">
                              <b-form-select v-if="otherData.conexion1 > 0" v-model="operarioConexion" :options="conexionData" size="sm"></b-form-select>
                            </b-col>
                            <b-col cols="12" sm="2" class="mb-3">
                              <b-button v-if="operarioConexionSeleccionado.length < otherData.conexion1" @click="setProvider('operarioConexion', 'conexionData')" variant="success">Agregar</b-button>
                              <b-button v-if="operarioConexionSeleccionado.length !== 0" @click="deleteOperatorScheduleSingle('operarioConexion')" variant="danger">Borrar</b-button>
                            </b-col>
                            <b-col cols="12" sm="12" class="mb-3">
                              <ol>
                                <li v-for="(operario, index) in operarioConexionSeleccionado" :key="index">
                                  <b-row>
                                    <b-col cols="12" sm="4" >
                                      <b-form-radio v-model="operarioConexionIndex" :value="operario.docId">{{ operario.name }}</b-form-radio>
                                    </b-col>
                                    <b-col v-if="operario.startTime !== undefined && operario.startTime !== null" cols="6" sm="3" >
                                      <b-time v-if="operario.editHour" v-model="operarioConexionSeleccionado[index].startTime" locale="en"></b-time>
                                      <span v-if="!operario.editHour" class="mr-1 font-weight-bold">Inicio:</span>
                                      <span v-if="!operario.editHour" class="mr-2">{{ formatAMPM(operario.startTime) }}</span>
                                      <span v-if="!operario.editHour" class="ml-2 text-warning hoverSpan" @click="operario.editHour=!operario.editHour">editar</span>
                                      <span v-else class="ml-2 text-success hoverSpan" @click="operario.editHour=!operario.editHour">OK</span>
                                    </b-col>
                                    <b-col cols="6" sm="3" >
                                      <b-time v-if="operario.editHour" v-model="operarioConexionSeleccionado[index].endTime" locale="en"></b-time>
                                      <span v-if="!operario.editHour" class="mr-1 font-weight-bold">Fin:</span>
                                      <span v-if="!operario.editHour && operario.endTime !== '-'" class="mr-2">{{ formatAMPM(operario.endTime) }}</span>
                                      <span v-if="operario.endTime === '-'" class="mr-2">Sin asignar</span>
                                      <span v-if="!operario.editHour" class="ml-2 text-warning hoverSpan" @click="operario.editHour=!operario.editHour">editar</span>
                                      <span v-else class="ml-2 text-success hoverSpan" @click="operario.editHour=!operario.editHour">OK</span>
                                    </b-col>
                                    <b-col cols="12" sm="2" >
                                      <b-button v-if="operario.endTime !== '-' && !operario.approved && datosAsambleas1.endHour !== '-'" variant="success" @click="approveService(operario, 'conexion', 'operarioConexionSeleccionado', index)">Aprobar</b-button>
                                      <span v-if="operario.endTime !== '-' && operario.approved" class="text-success" >Aprobado</span>
                                    </b-col>
                                  </b-row>
                                </li>
                              </ol>
                            </b-col>
                          </b-row>
                        </template>
                      </iq-card>
                    </tab-content-item>
                  </tab-content>
                </div>
              </b-col>
            </b-row>
              <div>
                <label class="ml-2" for="input-medium" @click="logCollapse = !logCollapse">
                  Registro de Actividad
                  <i v-if="!logCollapse" class="ri-arrow-drop-down-line ml-3"></i>
                  <i v-else class="ri-arrow-drop-up-line ml-3"></i>
                </label>
                <b-collapse :v-if="log.length > 0" v-model="logCollapse" id="logCollapse">
                  <div class="accordion" role="tablist">
                    <b-card v-for="(item, index) in log.slice().reverse()" :key="index" no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle="'accordion-'+index" variant="info">{{item.fecha}} - {{item.persona}} - {{item.operacion}}</b-button>
                      </b-card-header>
                      <b-collapse v-bind:id="'accordion-'+index" visible accordion="my-accordion" role="tabpanel">
                        <b-card-body v-for="(item1, index1) in item.acciones" :key="index1">
                          <b-card-text>Se actualizó el campo <code>{{item1.campo}}</code> de <code>{{item1.dato}}</code> a <code>{{item1.datoNuevo}}</code></b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
                </b-collapse>
              </div>
          </form>
        </ValidationObserver>
      </b-overlay>
      <template #modal-footer>
        <div class="d-flex justify-content-end">
          <b-button variant="danger" size="sm" class="float-right mr-2" @click="clear()">
          Cancelar
          </b-button>
          <b-button v-if="crudType === 'create'" @click="confirmModal=!confirmModal" variant="success" size="sm" class="float-right">
            Guardar
          </b-button>
          <b-button v-else @click="actualizarAsamblea(true)" variant="success" size="sm" class="float-right">
            Actualizar
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-container>
</template>
<script>
import 'ag-grid-community/dist/styles/ag-grid.min.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import { AllCommunityModules } from '@ag-grid-enterprise/all-modules'
// import { AgGridVue } from 'ag-grid-vue'
import { core } from '@/config/pluginInit'
import { db } from '@/config/firebase'
import axios from 'axios'
// IMPORT PARA LOS BOTONES DE SUBIR BASE DE DATOS, CREAR SALAS Y ZOOM
import UploadExcelComponent from '../../../../src/components/UploadExcel/index'
import LoaderProcess from '@/components/core/loader/LoaderProcess.vue'
import UserLoad from '@/components/CoProperties/UserLoad'
import ModalMeeting from '@/components/VideoCall/ModalMeeting'
import firebase from 'firebase'
const basicAuth = 'Basic YXBveW9sb2dpc3RpY29waEBnbWFpbC5jb206MjI2YzFiZDk2NTM0YzczODE2ZmU='
const { DateTime } = require('luxon')

function formatNumber (number) {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

export default {
  name: 'CollapsableModal',
  props: {
    inputs1: { type: Array, default: () => [] },
    inputs2: { type: Array, default: () => [] },
    inputs3: { type: Array, default: () => [] },
    show: { type: Boolean, default: () => false },
    queryData: { type: Object, default: () => {} },
    crudType: { type: String, default: () => '' },
    message: { type: String, default: () => '' }
  },
  data () {
    return {
      logCollapse: false,
      searchNames: true,
      dataCopy: null,
      // Ag-Grid Data
      cotizacionOptions: [
        { text: 'Presencial', value: 'presencial' },
        { text: 'Virtual', value: 'virtual' },
        { text: 'Mixta', value: 'mixta' },
        { text: 'Otros', value: 'otros' },
        { text: 'Cotizacion', value: 'cotizacion' },
        { text: 'Rechazada', value: 'rechazada' }
      ],
      overlay: false,
      claseAlpha: null,
      components: null,
      editType: null,
      itemsHeaders: [],
      itemsDataGrid: [],
      gridOptions: null,
      gridApi: null,
      style1: '',
      contador: 80,
      modules: AllCommunityModules,
      contactData: {},
      contactsData: [],
      contactsModel: {},
      data: {},
      orderData: {},
      logisticData: {},
      modalShow: false,
      confirmModal: false,
      collapse1: true,
      collapse2: false,
      collapse3: false,
      collapse4: false,
      prueba: [],
      dropdown1: '<ul class="dropdown-menu"><li><a href="#">Edit</a></li><li><a href="#">Delete</a></li><li role="separator" class="divider"></li><li><a href="#">Print</a></li></ul>',
      columns: [
        { label: 'Item', key: 'name', class: 'text-left' },
        { label: 'Descuento', key: 'discount', class: 'text-left' },
        { label: 'Precio', key: 'price', class: 'text-center' },
        { label: 'Impuesto', key: 'tax', class: 'text-center' },
        { label: 'Descripcion', key: 'description', class: 'text-center' },
        { label: 'Cantidad', key: 'quantity', class: 'text-center' },
        { label: 'Total', key: 'total', class: 'text-center' },
        { label: 'Action', key: 'action', class: 'text-center' }
      ],
      log: [],
      acciones: [],
      rows: [],
      indice: '',
      searchQuery: null,
      searchQueryName: null,
      resources: [],
      resourcesName: [],
      subTotal1: '',
      subTotal: '',
      discount: '',
      iva: '',
      total: '',
      nitFound: false,
      contactSelected: {},
      dataAlegra: {},
      itemsAlegra: [],
      cotizacion1: true,
      cotizacion: '',
      idCotizacion: '',
      emailCorreo: '',
      estadoCotizacion1: 'EN PROCESO',
      datosAsambleas2: {},
      datosAsambleas1: {
        boardId: null,
        id: null,
        name: '-',
        nit: '-',
        paymentStatus: 0,
        date: '-',
        endHour: '-',
        hour: '-',
        users: '-',
        admin: '-',
        empresa: '-',
        nombreContacto: '-',
        celularContacto: '-',
        contrato: false,
        location: '-',
        direccion: '-',
        telefonoporteria: '-',
        conductor: '-',
        fechaPedido: '-',
        fechaVencimiento: '-',
        cotizacion: '-',
        idCotizacion: '-',
        factura: '-',
        valorTotal: '-',
        anticipo: '-',
        estadoPago: '-',
        modalidad: 'cotizacion',
        canal: '-',
        basededatos: '-',
        sala: '-',
        usuarioZoom: '-',
        linkReunion: '-',
        descargaInformes: false,
        informes: '-',
        attendees: {
          id: 1,
          nombre: '_',
          confirmado: true,
          horaInicio: '9:00 a.m.',
          horaFin: '',
          horaExtra: '',
          entregoInformes: false
        },
        creator: {
          id: 1,
          nombre: 'Andrea Granda'
        },
        memo: {
          id: 1,
          date: '2020-11-24',
          hour: '10:00 a.m.',
          memo: 'Se envio cotizacion y se solicito cambio de sala y link de zoom'
        },
        log: [],
        operarioSonidoSeleccionado: [],
        sonido1: '-',
        acta1: '-',
        conexion1: '-',
        registro1: '-',
        soporte1: '-',
        virtual1: '-',
        index: null
      },
      otherData: {
        sonido1: 'null',
        acta1: 'null',
        conexion1: 'null',
        registro1: 'null',
        soporte1: 'null',
        virtual1: 'null',
        hasSonido: false,
        hasActa: false,
        hasConexion: false,
        hasRegistro: false,
        hasSoporte: false,
        hasVirtual: false
      },
      operariosDisponibles: false,
      dataAllProviders: [],
      conductorData: [],
      sonidoData: [],
      virtualData: [],
      soporteData: [],
      registroData: [],
      actaData: [],
      conexionData: [],
      operarioSonido: [],
      operarioActa: [],
      operarioConexion: [],
      operarioRegistro: [],
      operarioSoporte: [],
      operarioVirtual: [],
      operarioSonidoSeleccionado: [],
      operarioActaSeleccionado: [],
      operarioConexionSeleccionado: [],
      operarioRegistroSeleccionado: [],
      operarioSoporteSeleccionado: [],
      operarioVirtualSeleccionado: [],
      staffList: [],
      // LA DATA PARA LOS BOTONES DE SUBIR BASE DE DATOS, CREAR SALAS Y ZOOM
      current_step: 1,
      disabledBotonNext: false,
      hideExcel: true,
      tableHeader: [],
      tableData: [],
      spinner: false,
      consulta1: 'INSERT INTO `participantes` (`id`, `date_time`, `usuario`, `clave`, `nombre`, `porcentaje`, `correo`, `estado`, `apoderado`, `numero`, `hora_retiro`, `celular`, `registrar`, `puede_votar`, `qrcode_vota`) VALUES',
      properties: {
        confirmation: ''
      },
      value: 0,
      max: 0,
      message1: '',
      showModal2: false,
      soporteShow: false,
      type: 1,
      meetingUpdate: {},
      dataZoom1: {},
      // La data para guardar disponibilidad de operarios
      operatorTypes: ['operarioActaSeleccionado', 'operarioConexionSeleccionado', 'operarioRegistroSeleccionado', 'operarioSonidoSeleccionado', 'operarioSoporteSeleccionado', 'operarioVirtualSeleccionado'],
      weekOfYear: null,
      operarioSonidoIndex: '',
      operarioSoporteIndex: '',
      operarioVirtualIndex: '',
      operarioRegistroIndex: '',
      operarioConexionIndex: '',
      // La data para la hora de fin
      editEndTime: false,
      // La data para aprobar los servicios
      currentService: {},
      confirmServiceModal: false,
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'Agust', 'September', 'October', 'November', 'December']
    }
  },
  computed: {
    resultQuery () {
      if (this.searchQuery) {
        return this.resources.filter((item) => {
          return this.searchQuery.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v))
        })
      } else {
        return this.resources
      }
    },
    resultQueryName () {
      if (this.searchQueryName) {
        return this.resourcesName.filter((item) => {
          return this.searchQueryName.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v))
        })
      } else {
        return this.resourcesName
      }
    },
    getTotal () {
      return this.rows[this.indice].quantity * ((this.rows[this.indice].price / 100) * (100 - this.rows[this.indice].discount))
    },
    totalHours () {
      var tHour = null
      if (this.datosAsambleas1.endHour !== '-' && this.datosAsambleas1.hour !== '-') {
        const date = this.datosAsambleas1.date.split('-')
        const rawEndDate = this.datosAsambleas1.endDate.split('-')
        const startHour = this.datosAsambleas1.hour.split(':')
        const endHour = this.datosAsambleas1.endHour.split(':')
        var [year, month, day] = date
        var [year1, month1, day1] = rawEndDate
        var [hour, minute, second] = startHour
        var [hour1, minute1, second1] = endHour
        var startDate = DateTime.local(parseInt(year), parseInt(month), parseInt(day), parseInt(hour), parseInt(minute), parseInt(second))
        var endDate = DateTime.local(parseInt(year1), parseInt(month1), parseInt(day1), parseInt(hour1), parseInt(minute1), parseInt(second1))
        var elapsedTime = endDate.diff(startDate, 'minutes')
        var parcialTime = elapsedTime.values.minutes / 60
        var intTime = Math.floor(parcialTime)
        var dif = parcialTime - intTime
        console.log('la diferencia', dif)
        if (dif > 0) {
          if (dif <= 0.18) {
            tHour = intTime
            console.log('No es mayor de 0.18')
          }
          if (dif > 0.18 && dif < 0.59) {
            console.log('Mayor de 0.18')
            tHour = intTime + 0.5
          }
          if (dif >= 0.59) {
            console.log('Mayor de 0.25')
            tHour = Math.ceil(parcialTime)
          }
        } else {
          tHour = intTime
          console.log('No es mayor de 0.18')
        }
      }

      return tHour
    },
    // FUNCIONES PARA LOS BOTONES DE CREAR BASE DE DATOS, CREAR SALAS Y CREAR ZOOM
    progress: function () {
      return Math.round(100 / this.max_step) * this.current_step
    }
  },
  components: {
    // COMPONENTES PARA LOS BOTONES DE SUBIR BASE DE DATOS, CREAR SALAS Y ZOOM
    UploadExcelComponent,
    LoaderProcess,
    UserLoad,
    ModalMeeting
  },
  watch: {
    crudType (newValue, oldValue) {
      if (newValue === 'update') {
        this.datosAsambleas1 = this.queryData
        this.rows = []
        this.getData()
      }
      if (newValue === 'create') {
        this.rows = []
        // this.datosAsambleas1 = {}
        this.datosAsambleas1.id = 0
        this.datosAsambleas1.cotizacion = null
        this.datosAsambleas1.idCotizacion = null
        this.datosAsambleas1.fechaPedido = null
        this.datosAsambleas1.fechaVencimiento = null
        this.datosAsambleas1.name = null
        this.datosAsambleas1.nit = null
        this.datosAsambleas1.attendees = null
        this.getSubtotal()
        this.createData()
      }
    },
    show (newValue, oldValue) {
      if (newValue === true) { this.modalShow = true }
      if (newValue === false) { this.modalShow = false }
    }
  },
  beforeMount () {
    this.style1 = 'width: 100%; height: 100px;'
    this.gridOptions = {
      rowSelection: 'single'
    }
    this.itemsHeaders = [
      { headerName: 'Id', field: 'id', checkboxSelection: true },
      { headerName: 'Nombre ', field: 'name' },
      { headerName: 'Precio', field: 'price.0.price' },
      { headerName: 'Descripción', field: 'description' }
    ]
  },
  mounted () {
    core.index()
    this.gridApi = this.gridOptions.api
    this.gridOptions.singleClickEdit = true
    this.getItemsIndex()
    // this.getStaffIndex()
  },
  firestore () {
    return {
      claseAlpha: db.collection('campus').doc('Vs2FkUx38u3W8IDuABNF')
    }
  },
  methods: {
    formatAMPM (d) {
      const hour = d.split(':')
      var hours = hour[0]
      var minutes = hour[1]
      var ampm = hours >= 12 ? 'pm' : 'am'
      hours = hours % 12
      hours = hours === 0 ? 12 : hours // the hour '0' should be '12'
      hours = hours < 10 ? '0' + hours : hours
      var strTime = hours + ':' + minutes + ' ' + ampm
      return strTime
    },
    async setProvider (type, arrayType) {
      console.log(this[arrayType])
      var delay
      if (type === 'operarioSoporte' || type === 'operarioVirtual') {
        delay = 1
      } else { delay = 1.5 }
      const vm = this
      const typeOf = type + 'Seleccionado'
      const currOptions = this[arrayType]
      // datos de fecha
      const date = this.datosAsambleas1.date.split('-')
      const startHour = this.datosAsambleas1.hour.split(':')
      var [year, month, day] = date
      var [hour, minute, second] = startHour
      var rawStartTime = DateTime.local(parseInt(year), parseInt(month), parseInt(day), parseInt(hour), parseInt(minute), parseInt(second)).minus({ hours: delay }).c
      var startTime = `${rawStartTime.hour}:${rawStartTime.minute}:${rawStartTime.second}`
      console.log(startTime)
      console.log(currOptions)
      for (let index = 0; index < currOptions.length; index++) {
        console.log('coparación', currOptions[index].value, ' - ', vm[type])
        if (vm[type] === currOptions[index].value) {
          if (currOptions[index].available) {
            console.log('dispon', currOptions[index].available, currOptions[index].value)
            if (type === 'operarioSoporte') {
              this[typeOf].push({
                name: currOptions[index].text,
                docId: currOptions[index].value,
                totalServices: currOptions[index].totalServices,
                totalUsers: currOptions[index].totalUsers,
                staff: currOptions[index].staff,
                startTime: startTime,
                endTime: '-',
                available: true,
                editHour: false,
                approved: false
              })
            } else {
              this[typeOf].push({
                name: currOptions[index].text,
                docId: currOptions[index].value,
                totalServices: currOptions[index].totalServices,
                totalUsers: currOptions[index].totalUsers,
                staff: currOptions[index].staff,
                startTime: startTime,
                endTime: '-',
                available: false,
                editHour: false,
                approved: false
              })
            }
          } else { console.log('paila, ya está ocupado') }
        }
      }
      // const arrayTypePrev = this[arrayType]
      // console.log('Lista de sonido', arrayTypePrev)
      for (let index1 = 0; index1 < this.staffList.length; index1++) {
        var currStaff = this.staffList[index1]
        console.log('currStaff', currStaff)
        for (let index2 = 0; index2 < this[currStaff].length; index2++) {
          var currOperators = this[currStaff]
          console.log('currOperators', currOperators)
          for (let index3 = 0; index3 < currOperators.length; index3++) {
            if (this[type] === currOperators[index3].value) {
              console.log('Aquí ta', currOperators[index3])
              this[currStaff].splice(index3, 1)
            }
          }
        }
      }
      this.saveOperatorScheduleSingle(type, arrayType)
    },
    // deleteProvider (type) {
    //   const typeOf = type + 'Seleccionado'
    //   const indexName = type + 'Index'
    //   this[typeOf].splice(this[indexName], 1)
    //   this.deleteOperatorScheduleSingle(type, this[indexName])
    // },
    setSonido () {
      this.operarioSonidoSeleccionado.push(this.operarioSonido)
    },
    clearOperarios () {
      this.dataAllProviders = []
      this.conductorData = []
      this.sonidoData = []
      this.virtualData = []
      this.soporteData = []
      this.registroData = []
      this.actaData = []
      this.conexionData = []
      this.operarioSonido = []
      this.operarioActa = []
      this.operarioConexion = []
      this.operarioRegistro = []
      this.operarioSoporte = []
      this.operarioVirtual = []
      this.operarioSonidoSeleccionado = []
      this.operarioActaSeleccionado = []
      this.operarioConexionSeleccionado = []
      this.operarioRegistroSeleccionado = []
      this.operarioSoporteSeleccionado = []
      this.operarioVirtualSeleccionado = []
      this.otherData.sonido1 = null
      this.otherData.acta1 = null
      this.otherData.conexion1 = null
      this.otherData.registro1 = null
      this.otherData.soporte1 = null
      this.otherData.virtual1 = null
      this.otherData.hasSonido = null
      this.otherData.hasActa = null
      this.otherData.hasConexion = null
      this.otherData.hasRegistro = null
      this.otherData.hasSoporte = null
      this.otherData.hasVirtual = null
      this.staffList = []
    },
    async getStaffIndex () {
      this.dataAllProviders = []
      this.conductorData = []
      this.sonidoData = []
      this.registroData = []
      this.virtualData = []
      this.soporteData = []
      this.actaData = []
      this.conexionData = []
      const vm = this
      const splitedDate = await this.logisticData.fechaEvento.split('-')
      const splitedHour = this.logisticData.horaEvento.split(':')
      this.weekOfYear = DateTime.local(parseInt(splitedDate[0]), parseInt(splitedDate[1]), parseInt(splitedDate[2])).weekNumber
      const dayOfWeek = DateTime.local(parseInt(splitedDate[0]), parseInt(splitedDate[1]), parseInt(splitedDate[2])).weekday
      db.collection('/campus/Vs2FkUx38u3W8IDuABNF/availableProviders')
        .doc(`week${this.weekOfYear}`)
        .get()
        .then((doc) => {
          if (doc.exists) {
            if (doc.data()[dayOfWeek] !== undefined) {
              const rowData = doc.data()[dayOfWeek]
              const providers = rowData.providers
              console.log('normalito', providers)
              Object.keys(providers).forEach(function (key) {
                console.log('en key', providers[key])
                const provider = providers[key].staff
                const splitedStart = providers[key].start.split(':')
                const prevHour = DateTime.local(parseInt(splitedDate[0]), parseInt(splitedDate[1]), parseInt(splitedDate[2]), parseInt(splitedHour[0]), parseInt(splitedHour[1])).minus({ hours: 1 })
                const providerStart = DateTime.local(parseInt(splitedDate[0]), parseInt(splitedDate[1]), parseInt(splitedDate[2]), parseInt(splitedStart[0]), parseInt(splitedStart[1]))
                Object.keys(provider).forEach(function (key2) {
                  console.log('value:', key2)
                  switch (key2) {
                    case 'soporte':
                      if (providers[key].available && provider[key2] && prevHour >= providerStart) {
                        console.log(providers[key], 'se fue para ', key2)
                        // const staff = key2 + 'Data'
                        vm[key2 + 'Data'].push({
                          text: providers[key].name,
                          value: providers[key].docId,
                          totalServices: providers[key].totalServices,
                          totalUsers: providers[key].totalUsers,
                          staff: providers[key].staff,
                          available: providers[key].available
                        })
                        var staff = key2 + 'Data'
                        vm.staffList.push(staff)
                      }
                      break
                    default:
                      if (providers[key].totalServices < 1 && providers[key].available && provider[key2] && prevHour >= providerStart) {
                        console.log(providers[key], 'se fue para ', key2)
                        // const staff = key2 + 'Data'
                        vm[key2 + 'Data'].push({
                          text: providers[key].name,
                          value: providers[key].docId,
                          totalServices: providers[key].totalServices,
                          totalUsers: providers[key].totalUsers,
                          staff: providers[key].staff,
                          available: providers[key].available
                        })
                        staff = key2 + 'Data'
                        vm.staffList.push(staff)
                      }
                  }
                })
              })
            } else {
              console.log('No hay operarios disponibles para ese día')
              this.makeToast('danger', 'No hay operarios disponibles para ese día')
            }
          } else {
            console.log('No hay operarios disponibles para ese día')
            this.makeToast('danger', 'No hay operarios disponibles para ese día')
          }
        })
    },
    currencyFormatterCop (value) {
      return '\x24' + formatNumber(value)
    },
    formatNumber (number) {
      return Math.floor(number)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    getSubtotal () {
      this.subTotal1 = 0
      this.subTotal = 0
      this.discount = 0
      this.iva = 0
      this.total = 0
      for (let index = 0; index < this.rows.length; index++) {
        this.subTotal1 += (this.rows[index].price * this.rows[index].quantity)
        if (this.rows[index].discount) {
          this.discount += (this.rows[index].discount * (this.rows[index].price / 100) * this.rows[index].quantity)
        }
        if (this.rows[index].total) {
          this.subTotal += this.rows[index].total
          this.total += this.rows[index].total
        }
        if (this.rows[index].tax) {
          const iva1 = this.rows[index].total * (this.rows[index].tax / 100)
          this.iva += Math.trunc(iva1)
          this.total += Math.trunc(iva1)
        }
      }
      this.orderData.valorTotal = this.total
      this.datosAsambleas1.valorTotal = this.total
    },
    agregar (item) {
      // const index = this.rows.indexOf(item)
      this.searchQuery = item.name
      if (item.tax[0]) {
        this.rows[this.indice].tax = Math.trunc(item.tax[0].percentage)
      }
      this.rows[this.indice].id = item.id
      this.rows[this.indice].name = item.name
      // this.rows[this.indice].reference = item.reference
      this.rows[this.indice].price = Math.trunc(item.price[0].price)
      this.rows[this.indice].description = item.description
      this.rows[this.indice].total = this.getTotal
    },
    agregarName (item) {
      // const index = this.rows.indexOf(item)
      this.searchQueryName = item.name
      this.contactData.identificationObjectNumber = item.nit
      console.log(this.contactData.identificationObjectNumber)
    },
    add () {
      // const obj = this.default()
      // const ale = this.alegra()
      this.rows.push(this.default())
      // this.itemsAlegra.push(this.alegra())
    },
    default () {
      return {
        id: this.rows.length,
        name: '',
        price: '',
        tax: [],
        description: '',
        quantity: '1',
        discount: 0,
        total: '1',
        editable: false
      }
    },
    edit (item) {
      item.editable = true
      this.indice = this.rows.indexOf(item)
      this.searchQuery = item.name
    },
    submit (item) {
      this.rows[this.indice].total = this.getTotal
      this.getSubtotal()
      item.editable = false
    },
    remove (item) {
      const index = this.rows.indexOf(item)
      this.rows.splice(index, 1)
      this.getSubtotal()
    },
    onGridReady (params) {
      params.api.sizeColumnsToFit()
    },
    makeToast (variant = null, message) {
      var title1 = ''
      if (variant === 'success') {
        title1 = 'Proceso exitoso'
      } else if (variant === 'danger') {
        title1 = 'Proceso fallido'
      }
      this.$bvToast.toast(message, {
        title: title1,
        variant: variant,
        solid: true,
        autoHideDelay: 10000
      })
    },
    onRowSelected (event) {
      if (!event.node.selected) {
        return
      }
      const id = event.node.id
      this.productToDelete = id
    },
    onSelectionChanged (event) {
      // var rowCount = event.api.getSelectedNodes().length
      // window.alert('selection changed, ' + rowCount + ' rows selected')
    },
    getItemsIndex () {
      const vm = this
      db.collection('campus/Vs2FkUx38u3W8IDuABNF/items')
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach(doc => {
              if (doc.data() !== undefined) {
                const data = doc.data()
                Object.values(data).forEach(function (value) {
                  if (value.name != null) {
                    vm.resources.push(value)
                  }
                })
              }
            })
          }
        })
    },
    getNameIndex () {
      if (this.searchNames) {
        this.searchNames = false
        const vm = this
        db.collection('campus/Vs2FkUx38u3W8IDuABNF/contactsIndex')
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach(doc => {
                if (doc.data() !== undefined) {
                  const data = doc.data()
                  Object.values(data).forEach(function (value) {
                    if (value.name != null) {
                      vm.resourcesName.push(value)
                    }
                  })
                }
              })
            }
          })
      }
    },
    agregarLinea () {
      this.itemsDataGrid.push(
        { id: 1, name: this.dropdown1, price: 200, description: 'el mejor' }
      )
      this.contador = this.contador + 50
      this.style1 = `width: 100%; height: ${this.contador}px;`
    },
    createData () {
      for (let index = 0; index < this.inputs1.length; index++) {
        const foo = this.inputs1[index].dataModel
        this.contactData[foo] = null
      }
      for (let index = 0; index < this.inputs2.length; index++) {
        const foo = this.inputs2[index].dataModel
        this.orderData[foo] = null
      }
      for (let index = 0; index < this.inputs3.length; index++) {
        const foo = this.inputs3[index].dataModel
        this.logisticData[foo] = null
      }
      if (!this.logisticData.fechaEvento) {
        console.log('tiempo vacio', this.logisticData.fechaEvento)
        this.logisticData.fechaEvento = '-'
      }
    },
    async getData () {
      this.filterClient(this.datosAsambleas1.nit)
      this.orderData.users = this.datosAsambleas1.users
      this.orderData.empresa = this.datosAsambleas1.empresa
      this.orderData.nombreContacto = this.datosAsambleas1.nombreContacto
      this.orderData.celularContacto = this.datosAsambleas1.celularContacto
      this.orderData.Factura = this.datosAsambleas1.factura
      this.orderData.valorAnticipo = this.datosAsambleas1.anticipo
      this.orderData.estadoPago = this.datosAsambleas1.estadoPago
      this.logisticData.fechaEvento = this.datosAsambleas1.date
      this.logisticData.horaEvento = this.datosAsambleas1.hour
      this.logisticData.location = this.datosAsambleas1.location
      this.logisticData.direccion = this.datosAsambleas1.direccion
      this.logisticData.telefonoporteria = this.datosAsambleas1.telefonoporteria
      this.logisticData.conductor = this.datosAsambleas1.conductor
      this.logisticData.agendadoPor = this.datosAsambleas1.creator.nombre
      this.logisticData.observaciones = this.datosAsambleas1.memo.memo
      this.logisticData.modalidad = this.datosAsambleas1.modalidad
      this.logisticData.canal = this.datosAsambleas1.canal
      this.logisticData.basededatos = this.datosAsambleas1.basededatos
      this.logisticData.sala = this.datosAsambleas1.sala
      this.logisticData.usuarioZoom = this.datosAsambleas1.usuarioZoom
      this.logisticData.linkReunion = this.datosAsambleas1.linkReunion
      this.logisticData.descargaInformes = this.datosAsambleas1.descargaInformes
      this.logisticData.informes = this.datosAsambleas1.informes
      this.operarioSonidoSeleccionado = this.datosAsambleas1.operarioSonidoSeleccionado
      this.operarioActaSeleccionado = this.datosAsambleas1.operarioActaSeleccionado
      this.operarioConexionSeleccionado = this.datosAsambleas1.operarioConexionSeleccionado
      this.operarioRegistroSeleccionado = this.datosAsambleas1.operarioRegistroSeleccionado
      this.operarioSoporteSeleccionado = this.datosAsambleas1.operarioSoporteSeleccionado
      this.operarioVirtualSeleccionado = this.datosAsambleas1.operarioVirtualSeleccionado
      this.otherData.sonido1 = this.datosAsambleas1.sonido1
      this.otherData.acta1 = this.datosAsambleas1.acta1
      this.otherData.conexion1 = this.datosAsambleas1.conexion1
      this.otherData.registro1 = this.datosAsambleas1.registro1
      this.otherData.soporte1 = this.datosAsambleas1.soporte1
      this.otherData.virtual1 = this.datosAsambleas1.virtual1
      this.otherData.hasSonido = this.datosAsambleas1.hasSonido
      this.otherData.hasActa = this.datosAsambleas1.hasActa
      this.otherData.hasConexion = this.datosAsambleas1.hasConexion
      this.otherData.hasRegistro = this.datosAsambleas1.hasRegistro
      this.otherData.hasSoporte = this.datosAsambleas1.hasSoporte
      this.otherData.hasVirtual = this.datosAsambleas1.hasVirtual
      this.index = this.datosAsambleas1.index
      if (this.datosAsambleas1.log) {
        this.log = this.datosAsambleas1.log
      }
      if (this.datosAsambleas1.idCotizacion !== '-') {
        await axios({
          method: 'get',
          url: 'https://api.alegra.com/api/v1/estimates/' + this.datosAsambleas1.idCotizacion,
          headers: { Authorization: basicAuth }
        }).then(res => {
          this.cotizacion1 = false
          this.rows = res.data.items
          console.log('informacion de alegra', res.data)
          for (let index = 0; index < this.rows.length; index++) {
            this.rows[index].total = this.rows[index].price * this.rows[index].quantity
            if (this.rows[index].tax.length !== 0) {
              const taxes = Math.trunc(this.rows[index].tax[0].percentage)
              this.rows[index].tax = taxes
            } else { console.log('esta vacio') }
          }
          // this.itemsAlegra = res.data.items
          console.log('itemsAlegra1', this.itemsAlegra)
          console.log('rows1', this.rows)
          this.datosAsambleas1.fechaPedido = res.data.date
          this.datosAsambleas1.fechaVencimiento = res.data.dueDate
          this.cotizacion = this.datosAsambleas1.cotizacion
          this.idCotizacion = this.datosAsambleas1.idCotizacion
          this.getSubtotal()
        }).catch((error) => {
          this.makeToast('danger', 'Contacto no traido de Alegra ' + error.response.data.message)
        })
      }
    },
    async validate () {
      const valid = await (this.$refs.observerContact.validate())
      if (valid) {
        this.confirmModal = true
        this.guardarAsamblea()
      } else {
        this.makeToast('danger', 'Por favor, completa los campos requeridos')
      }
    },
    async sendToView () {
      this.confirmModal = false
      const vm = this
      await Object.keys(vm.data).forEach(function (key) {
        if (vm.data[key] === null || vm.data[key] === undefined) {
          vm.data[key] = '-'
        }
      })
      this.$emit('crudAction', { data: this.data, type: this.crudType })
    },
    async filterClient (nit, type) {
      var valid = false
      console.log('nit', nit)
      if (type === 'create' || nit === '-') {
        this.contactData = {}
        this.getSubtotal()
        // valid = await this.$refs.observerFilter.validate()
      } else {
        valid = true
      }
      if (valid) {
        await db.collection('campus/Vs2FkUx38u3W8IDuABNF/contacts')
          .where('identificationObject.number', '==', nit)
          .limit(1)
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach((doc) => {
                this.contactSelected.docId = doc.id
                // this.nitSearched = doc
                var obj = {}
                // *************Formateo de informacion
                obj.addressCountry = doc.data().address.country
                obj.addressDepartment = doc.data().address.department
                obj.addressCity = doc.data().address.city
                obj.addressAddress = doc.data().address.address
                obj.addressNeighborhood = doc.data().address.neighborhood
                obj.addressSector = doc.data().address.sector
                obj.identificationObjectNumber = doc.data().identificationObject.number
                obj.identificationObjectDv = doc.data().identificationObject.dv
                obj.identificationObjectType = doc.data().identificationObject.type
                if (doc.data().identificationObject.type === 'CC') {
                  obj.kindOfPerson = 'PERSON_ENTITY'
                } else if (doc.data().identificationObject.type === 'NIT') {
                  obj.kindOfPerson = 'LEGAL_ENTITY'
                }
                obj.email = doc.data().email
                obj.id = doc.data().id
                obj.name = doc.data().name
                obj.nameAdmin = doc.data().nameAdmin
                obj.phonePrimary = doc.data().phonePrimary
                obj.client = doc.data().clientType
                // Proceso para eliminar los null
                Object.keys(obj).forEach(function (key) {
                  if (obj[key] === null || obj[key] === undefined) {
                    obj[key] = '-'
                  }
                })
                // this.crudAction(obj, 'update')
                this.contactData = obj
                this.data = obj
                this.nitFound = true
                this.datosAsambleas1.name = doc.data().name
                this.datosAsambleas1.nit = this.contactData.identificationObjectNumber
                this.datosAsambleas1.admin = doc.data().nameAdmin
                this.datosAsambleas1.empresa = doc.data().empresa
              })
            } else {
              // TODO: Debo mostar una alerta informativa
              this.makeToast('danger', 'Contacto no encontrado')
              this.nitFound = false
              // this.nitFound = false
            }
          })
      } else {
      }
    },
    clear () {
      this.acciones = []
      this.data = {}
      this.searchQueryName = null
      this.cotizacion1 = true
      this.$emit('clearModal', { clear: true })
      this.operariosDisponibles = false
      this.clearOperarios()
    },
    clearBuscar () {
      this.resourcesName = []
      this.searchQueryName = null
    },
    async next (actual) {
      if (actual === 1) {
        const valid = await (this.$refs.observerContact.validate())
        if (valid) {
          this.collapse1 = false
          this.collapse2 = true
        } else core.showSnackbar('error', 'Debe completar los campos requeridos')
      } else {
        if (actual === 2) {
          this.collapse2 = false
          this.collapse3 = true
        } else {
          this.collapse3 = false
          this.collapse4 = true
        }
      }
    },
    async updateDataClient () {
      this.getContactsIndex()
      // this.sendToView()
      var proceed = false
      var kindOfPerson = ''
      if (this.data.identificationObjectType === 'NIT') {
        kindOfPerson = 'LEGAL_ENTITY'
      } else {
        kindOfPerson = 'PERSON_ENTITY'
      }
      const dataToAlegra = {
        name: this.data.name,
        phonePrimary: this.data.phonePrimary,
        email: this.data.email,
        id: this.data.id,
        ignoreRepeated: false,
        identificationObject: {
          number: this.data.identificationObjectNumber,
          type: this.data.identificationObjectType,
          dv: this.data.identificationObjectNumber,
          kindOfPerson: kindOfPerson
        },
        address: {
          address: this.data.addressAdress,
          city: this.data.addressCity,
          country: this.data.addressCountry,
          department: this.data.addressDepartment
        }
      }

      await axios({
        method: 'put',
        url: 'https://api.alegra.com/api/v1/contacts/?' + this.data.id,
        data: dataToAlegra,
        headers: { Authorization: basicAuth }
      }).then(res => {
        proceed = true
        this.makeToast('success', 'Contacto actualizado en Alegra')
      }).catch((error) => {
        this.makeToast('danger', 'Alegra no ha actualizado el contacto. ' + error.response.data.message)
      })
      if (proceed) {
        db.collection('campus/Vs2FkUx38u3W8IDuABNF/contacts')
          .doc(this.contactSelected.docId)
          .update(this.data)
          .then(() => {
            var newData = {
              name: this.data.name,
              email: this.data.email,
              phone: this.data.phonePrimary,
              nit: this.data.identificationObjectNumber,
              docId: this.contactSelected.docId,
              id: this.data.id,
              index: this.contactSelected.index
            }
            db.collection('campus/Vs2FkUx38u3W8IDuABNF/contactsIndex')
              .doc(`contactsIndex${this.contactSelected.index}`)
              .update({
                [this.contactSelected.docId]: newData
              }).then((indexDoc) => {
                // this.$emit('clearModal', { clear: true })
                this.makeToast('success', 'Contacto actualizado en Sistevot')
                // var rowNode = this.gridApi.getRowNode(this.rowNode)
                // rowNode.setData(newData)
                // this.gridApi.refreshCells()
              }).catch((error) => {
                this.makeToast('danger', 'Error al actualizar contacto en Sistevot. ', error)
              })
          }).catch((error) => {
            this.makeToast('danger', 'Error al actualizar contacto en Sistevot. ', error)
          })
      }
    },
    async crudAction ({ data, type }) {
      this.data = await data
      this.contactsModel.address.address = this.data.addressAdress
      this.contactsModel.address.country = this.data.addressCountry
      this.contactsModel.address.city = this.data.addressCity
      this.contactsModel.address.department = this.data.addressDepartment
      this.contactsModel.address.neighborhood = this.data.addressNeigborhood
      this.contactsModel.address.sector = this.data.addressSector
      this.contactsModel.name = this.data.name
      this.contactsModel.nameAdmin = this.data.nameAdmin
      this.contactsModel.email = this.data.email
      this.contactsModel.phonePrimary = this.data.phonePrimary
      this.contactsModel.id = this.data.id
      this.contactsModel.identificationObject.number = this.data.identificationObjectNumber
      this.contactsModel.identificationObject.dv = this.data.identificationObjectDv
      this.contactsModel.identificationObject.type = this.data.identificationObjectType

      if (type === 'create') {
        this.createContact()
      }
      if (type === 'update') {
        this.updateContact()
      }
    },
    getContactsIndex () {
      const vm = this
      db.collection('campus/Vs2FkUx38u3W8IDuABNF/contactsIndex')
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach(doc => {
              if (doc.data() !== undefined) {
                const data = doc.data()
                Object.values(data).forEach(function (value) {
                  if (value.nit === '98709214') {
                    vm.contactSelected.index = value.index
                  }
                  vm.contactsData.push(value)
                })
              }
            })
          }
        })
    },
    async saveDealRemision (alegra) {
      console.log('Rows iniciando save', this.rows)
      // var proceed = false
      this.createdAt = Date.now()
      var fecha = DateTime.now().plus({ days: 7 })
      if (alegra) {
        console.log('var alegra', alegra)
        this.itemsAlegra = this.rows
        for (let index = 0; index < this.itemsAlegra.length; index++) {
          if (this.itemsAlegra[index].tax === 19) {
            this.itemsAlegra[index].tax = [
              {
                id: 3
              }
            ]
          }
        }
        console.log('items alegra', this.itemsAlegra)
        await axios({
          method: 'post',
          url: 'https://api.alegra.com/api/v1/estimates/',
          data: {
            date: DateTime.now().c.year + '-' + DateTime.now().c.month + '-' + DateTime.now().c.day,
            dueDate: fecha.c.year + '-' + fecha.c.month + '-' + fecha.c.day,
            client: this.contactData.id,
            items: this.itemsAlegra
          },
          headers: { Authorization: basicAuth }
        }).then(res => {
          console.log('Respuesta Alegra Creando', res)
          this.datosAsambleas1.cotizacion = res.data.number
          this.datosAsambleas1.idCotizacion = res.data.id
          this.datosAsambleas1.fechaPedido = res.data.date
          this.datosAsambleas1.fechaVencimiento = res.data.dueDate
          // this.datosAsambleas1.modalidad = 'cotizacion'
          // this.logisticdata.modalidad = 'cotizacion'
          // this.cotizacion = res.data.number
          // this.idCotizacion = res.data.id
          this.cotizacion1 = false
          // this.saveDeal()
          this.makeToast('success', 'Cotizacion creada en Alegra')
        }).catch(error => {
          this.makeToast('danger', 'La cotizacion no fue creada en Alegra. ' + error.response.data.message)
        })
      } else {
        console.log('Aqui va el rows antes de', this.rows)
        this.itemsAlegra = this.rows
        for (let index = 0; index < this.itemsAlegra.length; index++) {
          if (this.itemsAlegra[index].tax === 19) {
            this.itemsAlegra[index].tax = [
              {
                id: 3
              }
            ]
          }
        }
        console.log('Aqui va el rows despues de', this.rows)
        await axios({
          method: 'put',
          url: 'https://api.alegra.com/api/v1/estimates/' + this.idCotizacion,
          data: {
            date: DateTime.now().c.year + '-' + DateTime.now().c.month + '-' + DateTime.now().c.day,
            dueDate: fecha.c.year + '-' + fecha.c.month + '-' + fecha.c.day,
            client: this.contactData.id,
            items: this.itemsAlegra
          },
          headers: { Authorization: basicAuth }
        }).then(res => {
          console.log('Respuesta Alegra Editando', res)
          // this.saveDeal()
          this.makeToast('success', 'Cotizacion actualizada en Alegra')
        }).catch(error => {
          this.makeToast('danger', 'La cotizacion no fue actualizada en Alegra. ' + error.response.data.message)
        })
      }
      this.modalShow2 = false
      this.modalSelected2 = false
      this.convertTax()
    },
    async cotizacionCorreo () {
      await axios({
        method: 'post',
        url: 'https://api.alegra.com/api/v1/estimates/' + this.idCotizacion + '/email',
        data: {
          emails: [
            this.emailCorreo
          ],
          sendCopyToUser: false
        },
        headers: { Authorization: basicAuth }
      }).then(res => {
        // this.saveDeal()
        this.makeToast('success', 'Cotizacion enviada por correo')
        this.emailCorreo = ''
      }).catch(error => {
        this.makeToast('danger', 'No se Envio la cotizacion por correo' + error.response.data.message)
      })
    },
    aprobarCotizacion () {
      this.estadoCotizacion1 = 'APROBADA'
      this.datosAsambleas1.modalidad = 'virtual'
      this.logisticdata.modalidad = 'virtual'
    },
    recaudarDatos () {
      this.datosAsambleas1.users = this.orderData.users
      this.datosAsambleas1.empresa = this.orderData.empresa
      this.datosAsambleas1.nombreContacto = this.orderData.nombreContacto
      this.datosAsambleas1.celularContacto = this.orderData.celularContacto
      this.datosAsambleas1.factura = this.orderData.Factura
      this.datosAsambleas1.anticipo = this.orderData.valorAnticipo
      this.datosAsambleas1.estadoPago = this.orderData.estadoPago
      this.datosAsambleas1.date = this.logisticData.fechaEvento
      this.datosAsambleas1.hour = this.logisticData.horaEvento
      this.datosAsambleas1.location = this.logisticData.location
      this.datosAsambleas1.direccion = this.logisticData.direccion
      this.datosAsambleas1.telefonoporteria = this.logisticData.telefonoporteria
      this.datosAsambleas1.conductor = this.logisticData.conductor
      this.datosAsambleas1.creator.nombre = this.logisticData.agendadoPor
      this.datosAsambleas1.memo.memo = this.logisticData.observaciones
      this.datosAsambleas1.modalidad = this.logisticData.modalidad
      this.datosAsambleas1.canal = this.logisticData.canal
      this.datosAsambleas1.basededatos = this.logisticData.basededatos
      this.datosAsambleas1.sala = this.logisticData.sala
      this.datosAsambleas1.usuarioZoom = this.logisticData.usuarioZoom
      this.datosAsambleas1.linkReunion = this.logisticData.linkReunion
      this.datosAsambleas1.descargaInformes = this.logisticData.descargaInformes
      this.datosAsambleas1.informes = this.logisticData.informes
      this.datosAsambleas1.operarioSonidoSeleccionado = this.operarioSonidoSeleccionado
      this.datosAsambleas1.operarioActaSeleccionado = this.operarioActaSeleccionado
      console.log('Operario registro seleccionado', this.operarioRegistroSeleccionado)
      this.datosAsambleas1.operarioConexionSeleccionado = this.operarioConexionSeleccionado
      this.datosAsambleas1.operarioRegistroSeleccionado = this.operarioRegistroSeleccionado
      this.datosAsambleas1.operarioSoporteSeleccionado = this.operarioSoporteSeleccionado
      this.datosAsambleas1.operarioVirtualSeleccionado = this.operarioVirtualSeleccionado
      this.datosAsambleas1.sonido1 = this.otherData.sonido1
      this.datosAsambleas1.acta1 = this.otherData.acta1
      this.datosAsambleas1.conexion1 = this.otherData.conexion1
      this.datosAsambleas1.registro1 = this.otherData.registro1
      this.datosAsambleas1.soporte1 = this.otherData.soporte1
      this.datosAsambleas1.virtual1 = this.otherData.virtual1
      this.datosAsambleas1.hasSonido = this.otherData.hasSonido
      this.datosAsambleas1.hasActa = this.otherData.hasActa
      this.datosAsambleas1.hasConexion = this.otherData.hasConexion
      this.datosAsambleas1.hasRegistro = this.otherData.hasRegistro
      this.datosAsambleas1.hasSoporte = this.otherData.hasSoporte
      this.datosAsambleas1.hasVirtual = this.otherData.hasVirtual
      this.datosAsambleas1.index = this.index
      this.datosAsambleas1.log = this.log
      if (this.logisticData.fechaEvento !== '-') {
        const splitedDate = this.logisticData.fechaEvento.split('-')
        const year = parseFloat(splitedDate[0])
        const month = parseFloat(splitedDate[1])
        const day = parseFloat(splitedDate[2])
        this.datosAsambleas1.boardId = DateTime.local(year, month, day).weekday
      }
    },
    async guardarAsamblea () {
      await this.comparar()
      const assembliesCount = this.claseAlpha.assembliesData.recordsCount / 250
      console.log(assembliesCount, '-', this.claseAlpha.assembliesData.recordsCount)
      if (Number.isInteger(assembliesCount)) {
        console.log('Crear nuevo indice')
        this.claseAlpha.assembliesData.indexCount++
        this.claseAlpha.assembliesData.recordsCount++
      } else {
        console.log('es impar')
        this.claseAlpha.assembliesData.recordsCount++
      }
      this.overlay = true
      this.confirmModal = false
      this.recaudarDatos()
      console.log('Estos son los datos de la asamblea', this.datosAsambleas1)
      const obj = this.datosAsambleas1
      Object.keys(obj).forEach(function (key) {
        if (obj[key] === null || obj[key] === undefined) {
          if (key === 'modalidad') {
            obj[key] = 'cotizacion'
          }
          obj[key] = '-'
        }
      })
      obj.id = this.claseAlpha.assembliesData.recordsCount
      obj.index = this.claseAlpha.assembliesData.indexCount
      await db.collection('campus').doc('Vs2FkUx38u3W8IDuABNF').update(this.claseAlpha)
        .then(() => {
          db.collection('campus/Vs2FkUx38u3W8IDuABNF/assemblies2022Index')
            .doc(`assembliesIndex${this.claseAlpha.assembliesData.indexCount}`)
            .set({
              [this.claseAlpha.assembliesData.recordsCount]: obj
            }, { merge: true })
            .then((doc) => {
              this.$emit('clearModal', { clear: true })
              this.clear()
              this.overlay = false
            })
        })
    },
    async actualizarAsamblea (clear) {
      // await this.comparar()
      console.log('datosAsambleas1', this.datosAsambleas1)
      const userInfo = JSON.parse(localStorage.getItem('userInformation'))
      const date = new Date()
      const formatedDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
      this.log.push(
        { fecha: formatedDate, persona: userInfo.name, operacion: 'Actualizacion', acciones: this.acciones }
      )
      this.recaudarDatos()
      console.log('datosAsambleas1', this.datosAsambleas1)
      const obj = this.datosAsambleas1
      if (obj.index === undefined || obj.index === null || obj.index === '-') {
        obj.index = 1
      }
      Object.keys(obj).forEach(function (key) {
        if (obj[key] === null || obj[key] === undefined) {
          obj[key] = '-'
        }
      })
      console.log('obj', obj)
      await db.collection('campus/Vs2FkUx38u3W8IDuABNF/assemblies2022Index')
        .doc(`assembliesIndex${obj.index}`)
        .set({
          [this.datosAsambleas1.id]: obj
        }, { merge: true })
        .then((doc) => {
          if (clear) {
            console.log('me fui a cerrar')
            this.$emit('clearModal', { clear: true })
            this.contactData = {}
            this.clear()
            this.modalShow = false
          } else { console.log('No cerré') }
        })
      // this.saveOperatorsSchedule()
    },
    // Método para pasar de index
    // async actualizarAsamblea () {
    //   // await this.comparar()
    //   console.log('datosAsambleas1', this.datosAsambleas1)
    //   this.recaudarDatos()
    //   console.log('datosAsambleas1', this.datosAsambleas1)
    //   const obj = this.datosAsambleas1
    //   obj.index = 2
    //   Object.keys(obj).forEach(function (key) {
    //     if (obj[key] === null || obj[key] === undefined) {
    //       obj[key] = '-'
    //     }
    //   })
    //   console.log('obj', obj)
    //   await db.collection('campus/Vs2FkUx38u3W8IDuABNF/assemblies2022Index')
    //     .doc(`assembliesIndex${obj.index}`)
    //     .set({
    //       [obj.id]: obj
    //     }, { merge: true })
    //     .then((doc) => {
    //       db.collection('campus/Vs2FkUx38u3W8IDuABNF/assemblies2022Index')
    //         .doc('assembliesIndex1')
    //         .update({
    //           [obj.id]: firebase.firestore.FieldValue.delete()
    //         }).then(() => {
    //           console.log('Borré la asamblea del index')
    //           this.$emit('clearModal', { clear: true })
    //           this.modalShow = false
    //           this.contactData = {}
    //           this.clear()
    //         })
    //     })
    //   this.saveOperatorsSchedule()
    // },
    convertTax () {
      console.log(this.rows)
      for (let index = 0; index < this.rows.length; index++) {
        this.rows[index].total = this.rows[index].price * this.rows[index].quantity
        if (this.rows[index].tax.length !== 0) {
          this.rows[index].tax = 19
        } else { console.log('esta vacio') }
      }
    },
    async comparar () {
      const vm = this
      this.acciones = []
      await db.collection('/campus/Vs2FkUx38u3W8IDuABNF/assemblies2022Index/')
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach(doc => {
              if (doc.data() !== undefined) {
                const data = doc.data()
                Object.values(data).forEach(function (value) {
                  if (value.id === vm.queryData.id) {
                    vm.datosAsambleas2 = value
                  }
                })
              }
            })
          }
        })
      const obj = this.datosAsambleas1
      const query = this.datosAsambleas2
      console.log('Datos Asambleas 1', obj)
      console.log('Datos Asambleas 2', query)
      this.recaudarDatos()
      Object.keys(obj).forEach(function (key) {
        // if (key !== 'log') {
        //   if (obj[key] !== query[key]) {
        //     if (obj[key][0] !== query[key][0]) {
        //       console.log(obj[key][0])
        //       console.log(query[key][0])
        //       // vm.acciones.push({ campo: key, dato: query[key], datoNuevo: obj[key] })
        //     }
        //   }
        // }
        if (key !== 'operarioRegistroSeleccionado' && key !== 'operarioActaSeleccionado' && key !== 'operarioSonidoSeleccionado' && key !== 'operarioConexionSeleccionado' && key !== 'operarioVirtualSeleccionado' && key !== 'operarioSoporteSeleccionado' && key !== 'log' && key !== 'memo' && key !== 'creator') {
          if (obj[key] !== query[key]) {
          // if (obj[key].length !== undefined && query[key].length !== undefined) {
          // if (key !== 'log') {
            // console.log('lenght1', obj[key].length, 'lenght2', query[key].length)
            // console.log('Cambios en el campo ', key, ' con el dato ', query[key], 'diferente a ', obj[key])
            vm.acciones.push({ campo: key, dato: query[key], datoNuevo: obj[key] })
          // vm.acciones.push({ campo: key, dato: query[key], datoNuevo: obj[key] })
          // }
          // }
          }
        }
      })
      console.log('Acciones', this.acciones)
    },
    // NUEVOS METODOS PARA LOS BOTONES DE IMPORTAR BASE DE DATOS, CREAR SALA Y CREAR USUARIO ZOOM
    closeMyModalAnCleanData () {
      this.current_step = 1
      this.tableData = []
      this.properties.confirmation = ''
      this.tableDataGrid = []
      this.$refs['my-modal'].hide()
    },
    onShown () {
      // Focus the cancel button when the overlay is showing
      this.$refs.cancel.focus()
    },
    onHidden () {
      // Focus the show button when the overlay is removed
      this.$refs.show.focus()
    },
    showModal () {
      this.$refs['my-modal'].show()
    },
    hideExcelField (showField) {
      this.hideExcel = showField
      if (showField === false) {
        this.disabledBotonNext = true
        this.newDataMap = []
      }
    },
    handleSuccess ({ results, header }) {
      if (results.length > 0 && header.length > 0) {
        core.showSnackbar('success', 'Se ha cargado un excel con informacion')
      } else {
        core.showSnackbar('error', 'El excel seleccionado parece no corresponder al formato solicitado o esta vacio')
      }
      this.disabledBotonNext = true
      this.tableData = results
      this.tableDataGrid = results
      this.tableHeader = header
    },
    beforeUpload (file) {
      const isLt1M = file.size / 1024 / 1024 < 1
      if (isLt1M) {
        return true
      }
      this.$message1({
        message: 'Please do not upload files larger than 1m in size.',
        type: 'warning'
      })
      return false
    },
    saveDataMap ({ data, totalCoef }) {
      this.newDataMap = data
      this.totalCoef = totalCoef
      this.disabledBotonNext = false
    },
    onClickNext (step) {
      if (step === 1) {
        this.$refs.obserValidation.validate().then((result) => {
          if (result) {
            this.current_step++
          }
        })
      } else {
        if (step === 2) {
          if (this.tableData.length > 0) {
            this.current_step++
          } else {
            core.showSnackbar('success', 'It is necessary to load information.')
          }
        } else {
          this.current_step++
        }
      }
    },
    onClickBack: function () {
      this.current_step--
      this.hideExcel = true
      this.tableHeader = []
      this.tableData = []
      this.disabledBotonNext = true
    },
    consulta () {
      let usuario = ''
      let nombre = ''
      let coeficiente = 0
      let mail = '@'
      let phones = 0
      for (let i = 0; i < this.newDataMap.length; i++) {
        usuario = this.newDataMap[i].idProperty
        nombre = this.newDataMap[i].name
        coeficiente = this.newDataMap[i].coefProperty
        if (this.newDataMap[i].mails !== 0) {
          mail = this.newDataMap[i].mails
        } else {
          mail = '@'
        }
        if (this.newDataMap[i].phones !== 0 && this.newDataMap[i].phones !== undefined) {
          phones = this.newDataMap[i].phones
        } else {
          phones = 0
        }
        const prueba = "(NULL, '0000-00-00 00:00:00', " + usuario + ', NULL, "' + nombre + '",' + coeficiente + ', "' + mail + '"' + ", 'No Registrados', NULL, '0', '0000-00-00 00:00:00.000000', " + phones + ", '0', NULL, NULL)"
        if (i === 0) {
          this.consulta1 = this.consulta1 + ' ' + prueba
        } else {
          this.consulta1 = this.consulta1 + ', ' + prueba
        }
      }
      console.log(this.consulta1)
    },
    async handleOk1 () {
      var pruebajuan = this.queryData.sala
      var pruebajuan2 = pruebajuan.indexOf('sistevot.com/')
      var pruebajuan3 = pruebajuan.slice(pruebajuan2 + 13)
      const vm = this
      this.spinner = true
      this.colorVariant = 'primary'
      this.message1 = 'Validando informacion ...'
      this.consulta()
      const mysql = 'sala=' + pruebajuan3 + '&consulta=' + this.consulta1
      await axios({
        method: 'post',
        url: 'https://salas4.sistevot.com/admin/post.php',
        data: mysql,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
        .then(function (response) {
        // handle success
          console.log(response)
          vm.max = 0
          vm.value = 0
          vm.spinner = false
          vm.message1 = ''
          vm.objectClean()
          vm.getData()
          vm.logisticData.basededatos = 'Subida en ' + pruebajuan3
          vm.datosAsambleas1.basededatos = 'Subida en ' + pruebajuan3
        // app.contacts.push(contact)
        // app.resetForm()
        })
        .catch(function (response) {
        // handle error
          console.log(response)
        })
    },
    objectClean () {
      this.current_step = 1
      this.tableHeader = []
      this.tableData = []
      this.newDataMap = []
      this.disabledBotonNext = false
      this.tableDataGrid = []
      this.properties.confirmation = ''
      this.closeMyModalAnCleanData()
    },
    async createSala () {
      const vm = this
      const nombre = this.contactData.name
      nombre.replace(/ /g, '%20')
      // Pedazo que le quité a de Juan
      // await db.collection('campus')
      //   .get()
      //   .then((querySnapshot) => {
      //     if (!querySnapshot.empty) {
      //       querySnapshot.forEach(doc => {
      //         if (doc.data() !== undefined) {
      //           vm.sala = doc.data().room
      //         }
      //       })
      //     }
      //   })
      await axios({
        method: 'get',
        url: 'https://salas4.sistevot.com/admin/index.php/?option=com_fabrik&amp;task=form.process&formid=3&amp;gestionar_salas___nombre=' + this.claseAlpha.room.alpha + this.claseAlpha.room.num + '&amp;gestionar_salas___asunto=ASAMBLEA ' + nombre + '&amp;gestionar_salas___fecha=' + this.logisticData.fechaEvento + '&amp;gestionar_salas___hora=' + this.logisticData.horaEvento,
        data: '',
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
        .then(function (response) {
        // handle success
          console.log('respuesta despues de crear la sala', response)
          if (response.status === 200) {
            vm.logisticData.descargaInformes = '/Asambleas 2022/salas4-' + vm.claseAlpha.room.alpha + vm.claseAlpha.room.num + ' - ASAMBLEA ' + vm.contactData.name
            vm.datosAsambleas1.descargaInformes = '/Asambleas 2022/salas4-' + vm.claseAlpha.room.alpha + vm.claseAlpha.room.num + ' - ASAMBLEA ' + vm.contactData.name
            var nuevaSala = 'https://salas4.sistevot.com/' + vm.claseAlpha.room.alpha + vm.claseAlpha.room.num
            console.log('Nueva Sala', nuevaSala)
            core.showSnackbar('success', 'sala creada con exito con el numero ' + vm.claseAlpha.roomalpha + vm.claseAlpha.roomnum)
            console.log('sala creada con exito con el numero', vm.claseAlpha.room.alpha + vm.claseAlpha.room.num)
            vm.logisticData.sala = nuevaSala
            vm.datosAsambleas1.sala = nuevaSala
            db.collection('campus')
              .doc('Vs2FkUx38u3W8IDuABNF')
              .set({
                room: {
                  alpha: 's',
                  num: firebase.firestore.FieldValue.increment(1)
                }
              }, { merge: true }).then((indexDoc) => {
                vm.makeToast('success', 'Indice de Sala creada')
                console.log('Creada el indice de sala', indexDoc)
              }).catch((error) => {
                console.log('Error creando el indice', error)
                vm.makeToast('danger', `Error al crear el indice. ${error}`)
              })
          } else {
            core.showSnackbar('danger', 'la sala no ha sido creada')
          }
        // app.contacts.push(contact)
        // app.resetForm()
        })
        .catch(function (response) {
        // handle error
          console.log(response)
        })
    },
    createMeeting ({ type, data }) {
      this.showModal2 = !data
      this.type = type
    },
    showAlertMessage (data) {
      this.meetingUpdate = {}
      core.showSnackbar(data.type, data.data)
    },
    changeModal ({ data }) {
      this.meetingUpdate = {}
      this.showModal2 = !data
    },
    async dataZoom ({ data }) {
      this.dataZoom1 = data
      console.log('Datos de zoom desde el emit', data)
      this.logisticData.linkReunion = data.join_url
      this.datosAsambleas1.linkReunion = data.join_url
      this.logisticData.usuarioZoom = data.host_email
      this.datosAsambleas1.usuarioZoom = data.host_email
      var pruebajuan = this.queryData.sala
      var pruebajuan2 = pruebajuan.indexOf('sistevot.com/')
      var pruebajuan3 = pruebajuan.slice(pruebajuan2 + 13)
      console.log('numero de la sala', pruebajuan3)
      const mysql = 'sala=' + pruebajuan3 + '&linkReunion=' + data.join_url + '&hostEmail=' + data.host_email
      await axios({
        method: 'post',
        url: 'https://salas4.sistevot.com/admin/zoom.php',
        data: mysql,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
        .then(function (response) {
          console.log('Datos cargados con exito', response)
        })
        .catch(function (response) {
          console.log('Datos NO cargados con exito', response)
        })
        // fin cambios salas sistevot
    },
    // Métodos para guardar disponibilidad de Operarios
    async saveOperatorsSchedule () {
      console.log('saving schedule', this.operatorTypes)
      var operatorTypes = this.operatorTypes
      const splitedDate = await this.logisticData.fechaEvento.split('-')
      this.weekOfYear = DateTime.local(parseInt(splitedDate[0]), parseInt(splitedDate[1]), parseInt(splitedDate[2])).weekNumber
      var day = DateTime.local(parseInt(splitedDate[0]), parseInt(splitedDate[1]), parseInt(splitedDate[2])).weekday
      var availability = {
        providers: {}
      }
      var vm = this
      for (let index = 0; index < operatorTypes.length; index++) {
        var currList = vm[operatorTypes[index]]
        if (currList.length > 0) {
          console.log(currList)
          for (let index2 = 0; index2 < currList.length; index2++) {
            availability[day] = await {
              providers: {
                [currList[index2].docId]: {
                  docId: currList[index2].docId,
                  name: currList[index2].name,
                  staff: currList[index2].staff,
                  totalServices: currList[index2].totalServices + 1,
                  totalUsers: currList[index2].totalUsers + parseInt(vm.orderData.users),
                  endTime: currList[index2].endTime,
                  available: currList[index2].available
                }
              }
            }
            await db.collection('/campus/Vs2FkUx38u3W8IDuABNF/availableProviders')
              .doc(`week${this.weekOfYear}`)
              .set(availability, { merge: true })
              .then(() => {
                console.log('Did it in ', currList[index2].name)
                db.collection('/campus/Vs2FkUx38u3W8IDuABNF/contacts')
                  .doc(currList[index2].docId)
                  .set({
                    schedule: {
                      [`week${this.weekOfYear}`]: {
                        [day]: {
                          totalServices: currList[index2].totalServices + 1,
                          totalUsers: currList[index2].totalUsers + parseInt(vm.orderData.users),
                          endTime: currList[index2].endTime,
                          available: currList[index2].available
                        }
                      }
                    }
                  }, { merge: true })
              })
            console.log(currList[index2].name, ' con totalUsers: ', currList[index2].totalUsers + parseInt(vm.orderData.users), 'y totalServices: ', currList[index2].totalServices + 1)
          }
        }
      }
    },
    async saveOperatorScheduleSingle (type, arrayType) {
      console.log('saving schedule single')
      const splitedDate = await this.logisticData.fechaEvento.split('-')
      this.weekOfYear = DateTime.local(parseInt(splitedDate[0]), parseInt(splitedDate[1]), parseInt(splitedDate[2])).weekNumber
      var day = DateTime.local(parseInt(splitedDate[0]), parseInt(splitedDate[1]), parseInt(splitedDate[2])).weekday
      var availability = {
        providers: {}
      }
      const typeOf = await type + 'Seleccionado'
      const currOptions = await this[typeOf]
      console.log('currOptions', currOptions)
      var vm = this
      for (let index2 = 0; index2 < currOptions.length; index2++) {
        if (vm[type] === currOptions[index2].docId) {
          currOptions[index2].totalServices += 1
          currOptions[index2].totalUsers += parseInt(vm.orderData.users)
          availability[day] = {
            providers: {
              [currOptions[index2].docId]: currOptions[index2]
            }
          }
          await db.collection('/campus/Vs2FkUx38u3W8IDuABNF/availableProviders')
            .doc(`week${this.weekOfYear}`)
            .set(availability, { merge: true })
            .then(() => {
              console.log('Did it, availability:', availability, ' weekOfYear', this.weekOfYear, 'la data original', currOptions[index2])
              db.collection('/campus/Vs2FkUx38u3W8IDuABNF/contacts')
                .doc(currOptions[index2].docId)
                .set({
                  schedule: {
                    [`week${this.weekOfYear}`]: {
                      [day]: {
                        totalServices: currOptions[index2].totalServices,
                        totalUsers: currOptions[index2].totalUsers,
                        startTime: currOptions[index2].startTime,
                        endTime: currOptions[index2].endTime,
                        available: currOptions[index2].available
                      }
                    }
                  }
                }, { merge: true })
                .then(() => {
                  this.actualizarAsamblea(false)
                }).catch((error) => {
                  console.error('Error de Firebase', error)
                  this.makeToast('danger', `No se pudo agendar el operario. Error: ${error}`)
                })
            }).catch((error) => {
              console.error('Error de Firebase', error)
              this.makeToast('danger', `No se pudo agendar el operario. Error: ${error}`)
            })
        } else { console.log('Ni mergas') }
        console.log(currOptions[index2].name, ' con totalUsers: ', currOptions[index2].totalUsers + parseInt(vm.orderData.users), 'y totalServices: ', currOptions[index2].totalServices + 1)
      }
    },
    async deleteOperatorScheduleSingle (type) {
      console.log('me vine a borrar de la BD', type, this.operarioRegistroSeleccionado)
      // const vm = this
      var indexName = this[`${type}Index`]
      var typeOf = type + 'Seleccionado'
      var currOptions = this[typeOf]
      console.log('IndexName', indexName)
      console.log('currOptions', currOptions)
      const splitedDate = await this.logisticData.fechaEvento.split('-')
      this.weekOfYear = DateTime.local(parseInt(splitedDate[0]), parseInt(splitedDate[1]), parseInt(splitedDate[2])).weekNumber
      var day = DateTime.local(parseInt(splitedDate[0]), parseInt(splitedDate[1]), parseInt(splitedDate[2])).weekday
      // var currentProvider = currentOptions[index]
      for (let index = 0; index < currOptions.length; index++) {
        if (currOptions[index].docId === indexName) {
          await db.collection('/campus/Vs2FkUx38u3W8IDuABNF/availableProviders')
            .doc(`week${this.weekOfYear}`)
            .set({
              [day]: {
                providers: {
                  [currOptions[index].docId]: {
                    available: true,
                    totalServices: firebase.firestore.FieldValue.increment(-1),
                    totalUsers: firebase.firestore.FieldValue.increment(-1 * parseInt(this.orderData.users)),
                    startTime: '-'
                  }
                }
              }
            }, { merge: true })
            .then(() => {
              console.log('Did it, weekOfYear', this.weekOfYear, 'la data original', currOptions[index])
              db.collection('/campus/Vs2FkUx38u3W8IDuABNF/contacts')
                .doc(currOptions[index].docId)
                .set({
                  schedule: {
                    [`week${this.weekOfYear}`]: {
                      [day]: {
                        available: true,
                        totalServices: firebase.firestore.FieldValue.increment(-1),
                        totalUsers: firebase.firestore.FieldValue.increment(-1 * parseInt(this.orderData.users)),
                        startTime: '-'
                      }
                    }
                  }
                }, { merge: true })
                .then(() => {
                  console.log('Guardé en el index ', index, 'indexName:', indexName, 'currOptions: ', currOptions)
                  const typeOf = type + 'Seleccionado'
                  this[typeOf].splice(index, 1)
                  console.log('borré los hptas datos')
                  this.actualizarAsamblea(false)
                }).catch((error) => {
                  console.error('Error de Firebase', error)
                  this.makeToast('danger', `No borrar el operario. Error: ${error}`)
                })
            }).catch((error) => {
              console.error('Error de Firebase', error)
              this.makeToast('danger', `No se pudo borrar el operario. Error: ${error}`)
            })
        }
      }
    },
    // Método para saber si la asamblea terminó al otro día del inicio
    async checkHours () {
      console.log('checking hours')
      const date = this.datosAsambleas1.date.split('-')
      var [year, month, day] = date
      if (this.logisticData.horaFin < this.logisticData.horaEvento) {
        console.log(this.logisticData.horaFin, ' es menor que ', this.logisticData.horaEvento)
        const rawEndDate = DateTime.local(parseInt(year), parseInt(month), parseInt(day)).plus({ days: 1 }).c
        this.datosAsambleas1.endDate = await rawEndDate.year + '-' + rawEndDate.month + '-' + rawEndDate.day
        // this.passDay = true
      } else {
        console.log(this.logisticData.horaFin, ' es mayor que', this.logisticData.horaEvento)
        this.datosAsambleas1.endDate = await this.datosAsambleas1.date
      }
      this.datosAsambleas1.endHour = this.logisticData.horaFin
      this.editEndTime = false
    },
    // Métodos para aprobar el servicio por cada proveedor
    approveService (operario, type, arrayType, index) {
      this.currentService = operario
      this.currentService.type = type
      this.currentService.arrayType = arrayType
      this.currentService.index = index
      this.confirmServiceModal = true
      this.calculateServicePrice()
    },
    confirmService () {
      // const vm = this
      var currentArray = this[this.currentService.arrayType]
      currentArray[this.currentService.index].approved = true
      console.log('currentArray', currentArray)
      var service
      const splitedDate = this.datosAsambleas1.date.split('-')
      const monthNumer = splitedDate[1]
      const month = this.months[parseInt(monthNumer) - 1]
      service = {
        [`${this.datosAsambleas1.id}-${this.currentService.docId}`]: {
          type: this.currentService.type,
          name: this.datosAsambleas1.name,
          date: this.datosAsambleas1.date,
          endDate: this.datosAsambleas1.endDate,
          hour: this.datosAsambleas1.hour,
          endHour: this.datosAsambleas1.endHour,
          value: this.currentService.totalValue,
          totalHours: this.currentService.totalHours,
          status: 'Pendiente'
        }
      }
      db.collection('campus/Vs2FkUx38u3W8IDuABNF/services')
        .doc(month)
        .set(service, { merge: true })
        .then(() => {
          console.log('Guardé el servicio', service)
          this.makeToast('success', 'Servicio guardado con éxito')
          this[this.currentService.arrayType] = currentArray
          this.actualizarAsamblea(false)
        }).catch((error) => {
          console.error('Error de Firebase', error)
          this.makeToast('danger', `No se pudo guardar el servicio. Error: ${error}`)
        })
      this.clearConfirmServiceModal()
    },
    clearConfirmServiceModal () {
      this.currentService = {}
      this.confirmServiceModal = false
    },
    calculateServicePrice () {
      var tHour = null
      if (this.currentService.endTime !== '-' && this.currentService.startTime !== '-') {
        const date = this.datosAsambleas1.date.split('-')
        const rawEndDate = this.datosAsambleas1.endDate.split('-')
        const startHour = this.currentService.startTime.split(':')
        const endHour = this.currentService.endTime.split(':')
        var [year, month, day] = date
        var [year1, month1, day1] = rawEndDate
        var [hour, minute, second] = startHour
        var [hour1, minute1, second1] = endHour
        var startDate = DateTime.local(parseInt(year), parseInt(month), parseInt(day), parseInt(hour), parseInt(minute), parseInt(second))
        var endDate = DateTime.local(parseInt(year1), parseInt(month1), parseInt(day1), parseInt(hour1), parseInt(minute1), parseInt(second1))
        var elapsedTime = endDate.diff(startDate, 'minutes')
        var parcialTime = elapsedTime.values.minutes / 60
        var intTime = Math.floor(parcialTime)
        var dif = parcialTime - intTime
        console.log('la diferencia', dif)
        if (dif > 0) {
          if (dif <= 0.18) {
            tHour = intTime
            console.log('No es mayor de 0.18')
          }
          if (dif > 0.18 && dif < 0.59) {
            console.log('Mayor de 0.18')
            tHour = intTime + 0.5
          }
          if (dif >= 0.59) {
            console.log('Mayor de 0.25')
            tHour = Math.ceil(parcialTime)
          }
        } else {
          tHour = intTime
          console.log('No es mayor de 0.18')
        }
      }
      this.currentService.totalHours = tHour
      if (tHour < this.claseAlpha.pricesTable[this.currentService.type].hours) {
        console.log('es menor: ', tHour, ' x ', this.claseAlpha.pricesTable[this.currentService.type].value)
        this.currentService.totalValue = this.claseAlpha.pricesTable[this.currentService.type].hours * this.claseAlpha.pricesTable[this.currentService.type].value
      } else {
        console.log('es mayor: ', tHour, ' x ', this.claseAlpha.pricesTable[this.currentService.type].value)
        this.currentService.totalValue = tHour * this.claseAlpha.pricesTable[this.currentService.type].value
      }
      console.log('Total de horas', tHour, 'Valor total:', this.currentService.totalValue)
    }
  }
}
</script>
<style scoped>
  .head-row {
    border-radius: 2rem;
    padding: 5px;
  }
  .hoverSpan {
    cursor: pointer;
  }
</style>
