<template>
  <b-container fluid>
    <b-sidebar id="sidebar-1" title="Filtros" right shadow>
      <div class="px-3 py-2">
        <b-form-checkbox class="switch-presencial" v-model="filters.presencial" switch size="sm"><i v-if="filters.presencial" class="fas fa-circle fa-xs switch-presencial"></i><i v-else class="far fa-circle fa-xs switch-presencial"></i> Presencial</b-form-checkbox>
        <b-form-checkbox class="switch-virtual" v-model="filters.virtual" switch size="sm"><i v-if="filters.virtual" class="fas fa-circle fa-xs switch-virtual"></i><i v-else class="far fa-circle fa-xs switch-virtual"></i> Virtual</b-form-checkbox>
        <b-form-checkbox class="switch-mixta" v-model="filters.mixta" switch size="sm"><i v-if="filters.mixta" class="fas fa-circle fa-xs switch-mixta"></i><i v-else class="far fa-circle fa-xs switch-mixta"></i> Mixta</b-form-checkbox>
        <b-form-checkbox class="switch-cotizacion" v-model="filters.cotizacion" switch size="sm"><i v-if="filters.cotizacion" class="fas fa-circle fa-xs switch-cotizacion"></i><i v-else class="far fa-circle fa-xs switch-cotizacion"></i> Cotizaciones</b-form-checkbox>
        <b-button @click="getWeekDates(weekDate)">Aplicar Filtros</b-button>
      </div>
      <div class="fixed-bottom pl-2">
        <p class="text-primary mb-0">
          <i class="fas fa-chalkboard-teacher fa-xs mr-1"></i>
          <a style="color:#9eb0e7" class="font-size-12 text-primary mr-1">Virtual</a>
          <i class="fas fa-volume-up fa-xs mr-1"></i>
          <a style="color:#9eb0e7" class="font-size-12 text-primary mr-1">Presencial</a>
          <i class="fas fa-sticky-note fa-xs mr-1"></i>
          <a style="color:#9eb0e7" class="font-size-12 text-primary mr-1">Cotización</a>
        </p>
      </div>
    </b-sidebar>
    <b-row>
      <b-col md="12">
        <b-row>
          <b-col cols="12 text-right mb-4">
          </b-col>
        </b-row>
        <b-row align-v="baseline">
          <b-col align-self="start" class="text-left pl-0" cols="3">
          <b-button size="sm" class="mb-1" block @click="addAssemblie()">Añadir Asamblea <i class="fas fa-plus-circle fa-xs"></i></b-button>
          </b-col>
          <b-col cols="6" class="text-center">
            <b-button-group class="mb-1">
              <b-button size="sm" @click="changeWeek('prev')"><i class="fas fa-chevron-left"></i></b-button>
              <b-button size="sm" class="btn-date text-dark" variant="outline-secondary">{{this.startMonth}} {{this.date.weekStart.day}} - {{this.endMonth}} {{this.date.weekEnd.day}}, {{this.weekDate.year}}</b-button>
              <b-button size="sm" @click="changeWeek('next')"><i class="fas fa-chevron-right"></i></b-button>
            </b-button-group>
          </b-col>
          <b-col align-self="end" cols="3" class="text-right pr-0">
            <b-button variant="outline-secondary" class="mb-1 mr-1" size="sm" v-b-toggle.sidebar-1>Filtros <i class="fas fa-filter"></i></b-button>
          </b-col>
        </b-row>
        <div class="row">
          <div class="col-md-12 track pl-0 pr-0" style="min-height:50vh">
            <iq-card id="foo" bodyClass="task-body" headerClass="task-header" class="bg-transparent shadow-none w-15" v-for="(item, index) in boards" :key="index">
              <template v-slot:headerTitle>
                <div style="background-color: white; border-radius: 7px; padding: 1px 5px 1px 5px;">
                  <p class="text-primary mb-0">
                    <i class="fas fa-chalkboard-teacher fa-xs mr-1"></i>
                    <a style="color:#9eb0e7" class="font-size-12 text-primary mr-1">{{ item.avalible.virtual }}</a>
                    <i class="fas fa-volume-up fa-xs mr-1"></i>
                    <a style="color:#9eb0e7" class="font-size-12 text-primary mr-1">{{ item.avalible.presencial }}</a>
                    <i class="fas fa-sticky-note fa-xs mr-1"></i>
                    <a style="color:#9eb0e7" class="font-size-12 text-primary mr-1">{{ item.avalible.cotizacion }}</a>
                  </p>
                </div>
                <a class="text-white font-size-18 mr-1">{{item.date}}</a>
                <a class="text-white font-size-12">{{ item.title }}</a>
              </template>
              <template v-slot:body>
                <i  v-for="(element, index) in item.list"
                    :key="element.name +'-'+ index"
                    @dblclick="editAssemblie(element, item)"
                >
                  <iq-card
                    bodyClass="task-card"
                    headerClass="task-card-title"
                    class="mb-2"
                  >
                    <template v-slot:headerTitle>
                      <a class="card-title text-muted">{{ element.name }}</a>
                    </template>
                    <template v-slot:body>
                      <div>
                        <p class="font-size-11 mb-0">
                          <i class="fas fa-users fa-xs mr-1"></i>
                          <span class="mr-1">{{ element.users }}</span>
                          <i class="fas fa-clock fa-xs mr-1"></i>
                          <span>{{ formatAMPM(element.hour) }}</span>
                        </p>
                        <p class="font-size-12">
                          {{element.admin}}
                        </p>
                        <div class="d-flex justify-content-between">
                          <p>
                          </p>
                        </div>
                      </div>
                      <b-progress style="height: 4px" class="mt-2" :max="100" show-value>
                        <b-progress-bar :value="100" class="iq-border-radius-10" :variant="element.type" label=" " />
                      </b-progress>
                    </template>
                  </iq-card>
                </i>
              </template>
            </iq-card>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core, flatpickerSetting } from '../../../config/pluginInit'
const { DateTime } = require('luxon')
// import draggable from 'vuedraggable'

export default {
  name: 'AssembliesCalendar',
  props: {
    rowDataCard: { type: Array, default: () => [] }
  },
  data () {
    return {
      dateConfig: flatpickerSetting,
      sortedDataCard: [],
      filters: {
        presencial: true,
        virtual: true,
        mixta: true,
        cotizacion: true
      },
      dataCard: [],
      weekDate: DateTime.now().c,
      date: {
        day: 0,
        weekStart: 0,
        weekEnd: 0,
        today: {
          day: 0,
          month: 0,
          year: 0
        }
      },
      status: [
        { text: 'Low', value: 0 },
        { text: 'High', value: 1 },
        { text: 'Critical', value: 2 }
      ],
      colors: [
        'bg-primary',
        'bg-success',
        'bg-warning',
        'bg-info',
        'bg-danger'
      ],
      board: this.defaultBoard(),
      task: this.defaultTask(),
      boards: [
        {
          id: 1,
          title: 'Lunes',
          date: '',
          color: 'bg-primary',
          avalible: {
            presencial: 10,
            virtual: 15,
            mixta: 8,
            cotizacion: 0
          },
          list: []
        },
        {
          id: 2,
          title: 'Martes',
          date: '',
          color: 'bg-success',
          avalible: {
            presencial: 8,
            virtual: 15,
            mixta: 8,
            cotizacion: 0
          },
          list: []
        },
        {
          id: 3,
          title: 'Miércoles',
          date: '',
          color: 'bg-info',
          avalible: {
            presencial: 8,
            virtual: 15,
            mixta: 8,
            cotizacion: 0
          },
          list: [
          ]
        },
        {
          id: 4,
          title: 'Jueves',
          date: '',
          color: 'bg-warning',
          avalible: {
            presencial: 8,
            virtual: 15,
            mixta: 8,
            cotizacion: 0
          },
          list: []
        },
        {
          id: 5,
          title: 'Viernes',
          date: '',
          color: 'bg-danger',
          avalible: {
            presencial: 8,
            virtual: 15,
            mixta: 8,
            cotizacion: 0
          },
          list: []
        },
        {
          id: 6,
          title: 'Sábado',
          date: '',
          color: 'bg-danger',
          avalible: {
            presencial: 8,
            virtual: 15,
            mixta: 8,
            cotizacion: 0
          },
          list: []
        },
        {
          id: 7,
          title: 'Domingo',
          date: '',
          color: 'bg-danger',
          avalible: {
            presencial: 8,
            virtual: 15,
            mixta: 8,
            cotizacion: 0
          },
          list: []
        }
      ]
    }
  },
  watch: {
    rowDataCard (newValue) {
      if (newValue.length >= 1) {
        this.getWeekDates(this.weekDate)
      } else {
        this.$emit('loadSuccess', { type: false })
      }
    }
  },
  mounted () {
    core.index()
    this.getWeekDates(this.weekDate)
  },
  computed: {
    startMonth () {
      const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
      return months[this.date.weekStart.month - 1]
    },
    endMonth () {
      const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
      return months[this.date.weekEnd.month - 1]
    },
    formatedDate () {
      return this.formatDate(this.weekDate)
    }
  },
  methods: {
    formatAMPM (d) {
      const hour = d.split(':')
      var hours = hour[0]
      var minutes = hour[1]
      var ampm = hours >= 12 ? 'pm' : 'am'
      hours = hours % 12
      hours = hours === 0 ? 12 : hours // the hour '0' should be '12'
      hours = hours < 10 ? '0' + hours : hours
      var strTime = hours + ':' + minutes + ' ' + ampm
      return strTime
    },
    async getWeekDates (myDate) {
      for (let index2 = 0; index2 < this.boards.length; index2++) {
        this.boards[index2].list = await []
        this.boards[index2].avalible = {
          presencial: 8,
          virtual: 15,
          mixta: 8,
          cotizacion: 0
        }
      }
      this.date.today.day = DateTime.now.day
      this.date.today.month = DateTime.now.month
      this.date.today.year = DateTime.now.year
      this.date.weekStart = await DateTime.local(myDate.year, myDate.month, myDate.day).startOf('week').c
      this.date.weekEnd = DateTime.local(myDate.year, myDate.month, myDate.day).endOf('week').c
      for (let index = 0; index < this.boards.length; index++) {
        this.boards[index].date = DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).plus({ days: index }).c.day
      }
      this.getCardsInfo()
    },
    getCardsInfo () {
      // console.log('trayendo los datos', this.rowDataCard.length)
      for (let index = 0; index < this.rowDataCard.length; index++) {
        var splitedDate
        if (this.rowDataCard[index].date !== undefined || this.rowDataCard[index].date !== null) {
          // console.log('prueba', this.rowDataCard[index], 'index:', this.rowDataCard[index].index)
          splitedDate = this.rowDataCard[index].date.split('-')
        }
        const year = parseFloat(splitedDate[0])
        const month = parseFloat(splitedDate[1])
        const day = parseFloat(splitedDate[2])
        const cardDay = DateTime.local(year, month, day).ordinal
        const startDate = DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).ordinal
        const endDate = DateTime.local(this.date.weekEnd.year, this.date.weekEnd.month, this.date.weekEnd.day).ordinal
        if (cardDay >= startDate && cardDay <= endDate) {
          // console.log('Its between')
          for (let index2 = 0; index2 < this.boards.length; index2++) {
            if (this.boards[index2].id === this.rowDataCard[index].boardId) {
              // console.log('Is ths board', this.boards[index2].id, this.rowDataCard[index].admin)
              const filters = this.filters
              if (filters[this.rowDataCard[index].type] === true) {
                const typeOf = this.rowDataCard[index].type
                if (typeOf === 'virtual') {
                  this.boards[index2].avalible[typeOf] -= 1
                }
                if (typeOf === 'presencial') {
                  this.boards[index2].avalible.presencial -= 1
                }
                if (typeOf === 'mixta') {
                  this.boards[index2].avalible.presencial -= 1
                  this.boards[index2].avalible.virtual -= 1
                }
                if (typeOf === 'cotizacion') {
                  this.boards[index2].avalible.cotizacion += 1
                }
                // console.log('antes de', this.boards[index2].avalible[typeOf])
                // console.log('despues de', this.boards[index2].avalible[typeOf])
                this.boards[index2].list.push(this.rowDataCard[index])
              }
            }
          }
          this.$emit('loadSuccess', { type: false })
        } else {
          this.$emit('loadSuccess', { type: false })
        }
      }
    },
    async changeWeek (type) {
      for (let index2 = 0; index2 < this.boards.length; index2++) {
        this.boards[index2].list = await []
        this.boards[index2].avalible = {
          presencial: 8,
          virtual: 15,
          mixta: 8,
          cotizacion: 0

        }
      }
      // console.log('listas vacias')
      if (type === 'next') {
        this.weekDate = await DateTime.local(this.weekDate.year, this.weekDate.month, this.weekDate.day).plus({ days: 7 }).c
        // console.log('add', this.weekDate)
        this.getWeekDates(this.weekDate)
      }
      if (type === 'prev') {
        this.weekDate = await DateTime.local(this.weekDate.year, this.weekDate.month, this.weekDate.day).minus({ days: 7 }).c
        this.getWeekDates(this.weekDate)
        // console.log('minus', this.weekDate)
      }
    },
    defaultBoard () {
      return {
        id: 0,
        title: '',
        color: '',
        list: [
        ]
      }
    },
    defaultTask () {
      return {
        id: 0,
        name: '',
        description: '',
        status: 0
      }
    },
    editAssemblie (element, item) {
      this.$emit('editAction', { params: element })
    },
    saveBoard (item) {
      const board = this.boards.findIndex(b => b.id === item.id)
      if (board === -1) {
        item.id = this.boards.length
        item.color = this.colors[Math.floor(Math.random() * this.colors.length)]
        this.boards.push(item)
      }
      this.board = this.defaultBoard()
      this.$bvModal.hide('board')
    },
    addAssemblie () {
      this.$emit('createOrder')
    },
    async filterAssemblies () {
      for (let index2 = 0; index2 < this.boards.length; index2++) {
        this.boards[index2].list = await []
        this.boards[index2].avalible = {
          presencial: 8,
          virtual: 15,
          mixta: 8,
          cotizacion: 0
        }
      }
      this.getCardsInfo()
    }
  }
}
</script>
<style scoped>
.btn-date{
  /* width: 40vh; */
  pointer-events: none;
}
.indicative {
  background-color: red;
}
.switch-presencial {
  color: var(--iq-presencial)
}
.switch-virtual {
  color: var(--iq-virtual)
}
.switch-mixta {
  color: var(--iq-mixta)
}
.switch-cotizacion {
  color: var(--iq-cotizacion)
}
</style>
