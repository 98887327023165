<template>
  <b-overlay :show="overlay" rounded="sm">
    <b-container fluid>
    <div>
      <b-tabs v-model="tabActive" content-class="mt-3">
        <b-tab title="Calendario" active>
          <assemblies-calendar
            :rowDataCard="calendarData"
            @createOrder="createOrder"
            @editAction="editFromCalendar"
            @loadSuccess="loadSuccess"
          ></assemblies-calendar>
        </b-tab>
        <b-tab title="Lista">
          <b-button size="sm" class="mb-1" variant="danger" :disabled="!deleteBtn" block @click="deleteAssemblie()">Cancelar Pedido <i class="fas fa-exclamation-triangle fa-xs"></i></b-button>
          <div class="mt-10"  >
            <ag-grid-vue
              id="ag-grid"
              class="ag-theme-material border height-500"
              :enableCellTextSelection="true"
              :headerHeight="30"
              :floatingFiltersHeight="40"
              :columnDefs="AssembliesHeaders"
              :defaultColDef="defaultColDef"
              :rowData="scheduleList"
              :floatingFilter="true"
              :modules="modules"
              rowSelection="single"
              :resizable="true"
              :pagination="true"
              :paginationPageSize="paginationPageSize"
              @row-selected="onAssemblieSelected"
              @selection-changed="onSelectionChanged"
              @rowDoubleClicked="onRowClicked2"
              style="width: 100%; height: 70vh;"
            ></ag-grid-vue>
          </div>
        </b-tab>
        <b-tab title="Por definir">
          <b-button size="sm" v-if="deleteBtn" class="mb-1" variant="danger" block @click="deleteAssemblie()">Cancelar Pedido <i class="fas fa-exclamation-triangle fa-xs"></i></b-button>
          <div class="mt-10"  >
            <ag-grid-vue
              id="ag-grid"
              class="ag-theme-material border height-500"
              :enableCellTextSelection="true"
              :columnDefs="AssembliesHeaders"
              :gridOptions="gridOptions"
              :defaultColDef="defaultColDef"
              :rowData="undefinedList"
              :floatingFilter="true"
              :modules="modules"
              rowSelection="single"
              :resizable="true"
              :pagination="true"
              :paginationPageSize="paginationPageSize"
              @row-selected="onAssemblieSelected"
              @selection-changed="onSelectionChanged"
              @rowDoubleClicked="onRowClicked2"
              style="width: 100%; height: 70vh;"
            ></ag-grid-vue>
          </div>
        </b-tab>
        <b-tab title="Cancelados">
          <div class="mt-10"  >
            <ag-grid-vue
              id="ag-grid"
              class="ag-theme-material border height-500"
              :enableCellTextSelection="true"
              :columnDefs="AssembliesHeaders"
              :defaultColDef="defaultColDef"
              :rowData="cancelledList"
              :floatingFilter="true"
              :modules="modules"
              rowSelection="single"
              :resizable="true"
              :pagination="true"
              :paginationPageSize="paginationPageSize"
              @rowDoubleClicked="onRowClicked2"
              style="width: 100%; height: 70vh;"
            ></ag-grid-vue>
          </div>
        </b-tab>
        <b-tab title="Logistica">
          <div class="mt-10"  >
            <b-form-datepicker
              type="text" id="docTypeFormControl1" v-model="filtroFecha">
            </b-form-datepicker>
            <b-button v-on:click=llenarFecha>Buscar</b-button>
            <hr>
            <div>
              <b-row>
                <b-col class="text-center mydiv">
                  <i class="fas fa-volume-up fa-lg"></i>
                  <div>Sonido Completo<div></div>{{ sonidosCompletos }} de 10</div>
                </b-col>
                <b-col class="text-center">
                  <i class="fa-solid fa-music"></i>
                  <div>Bafles<div></div>{{ bafles }} de 14</div>
                </b-col>
                <b-col class="text-center">
                  <i class="fa-brands fa-chromecast"></i>
                  <div>Video Beam<div></div>{{ videobeam }} de 10</div>
                </b-col>
                <b-col class="text-center mydiv">
                  <i class="fa-solid fa-tablet"></i>
                  <div>Pantalla<div></div>{{ pantalla }} de 10</div>
                </b-col>
                <b-col class="text-center">
                  <i class="fa-solid fa-laptop"></i>
                  <div>Computadores<div></div>{{ computadores }} de 24</div>
                </b-col>
                <b-col class="text-center">
                  <i class="fa fa-wifi"></i>
                  <div>Conexiones<div></div>{{ conexiones }}</div>
                </b-col>
                <b-col class="text-center">
                  <i class="fa fa-thumbs-up"></i>
                  <div>Votacion Electronica<div></div>{{ votacionElectronica }}</div>
                </b-col>
                <b-col class="text-center">
                  <i class="fa-solid fa-qrcode"></i>
                  <div>Fichos QR<div></div>{{ fichosQr }}</div>
                </b-col>
                <b-col class="text-center">
                  <i class="fa fa-calculator"></i>
                  <div>Controles<div></div>{{ controles }}</div>
                </b-col>
              </b-row>
            </div>
            <hr>
            <ag-grid-vue
              id="ag-grid"
              class="ag-theme-material border height-500"
              :enableCellTextSelection="true"
              :columnDefs="filtrosLogistica"
              :defaultColDef="defaultColDef"
              :rowData="fechaFiltrada"
              :floatingFilter="true"
              :modules="modules"
              rowSelection="single"
              :resizable="true"
              :pagination="true"
              :paginationPageSize='100'
              :groupDisplayType="groupDisplayType"
              :enableRangeSelection="true"
              :animateRows="true"
              style="width: 100%; height: 70vh;"
            ></ag-grid-vue>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <collapsable-modal
      :inputs1="inputs1"
      :inputs2="inputs2"
      :inputs3="inputs3"
      :crudType="crudType"
      :show="modalShow"
      :queryData="dataToModal"
      :message="message"
      @clearModal="clearModal"
    >
    </collapsable-modal>
    </b-container>
  </b-overlay>
</template>
<script>
import 'tui-time-picker/dist/tui-time-picker.css'
import 'tui-date-picker/dist/tui-date-picker.css'
import 'tui-calendar/dist/tui-calendar.css'
import 'ag-grid-community/dist/styles/ag-grid.min.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import { AllCommunityModules } from '@ag-grid-enterprise/all-modules'
import { AgGridVue } from 'ag-grid-vue'
import { core } from '../../config/pluginInit'
import { db } from '@/config/firebase'
import CollapsableModal from './actions/CollapsableModal.vue'
import AssembliesCalendar from './actions/AssembliesCalendar.vue'
import axios from 'axios'
const basicAuth = 'Basic YXBveW9sb2dpc3RpY29waEBnbWFpbC5jb206MjI2YzFiZDk2NTM0YzczODE2ZmU='

export default {
  name: 'Assemblies',
  components: {
    CollapsableModal,
    AssembliesCalendar,
    AgGridVue
  },
  data () {
    return {
      tabActive: 0,
      message: 'pedido',
      deleteBtn: false,
      scheduleList: null,
      assemblieSelected: null,
      cancelledList: [],
      undefinedList: [],
      calendarData: [],
      globalInfo: {},
      overlay: true,
      modules: AllCommunityModules,
      paginationPageSize: 20,
      defaultColDef: { resizable: true },
      AssembliesData: [],
      nit: '',
      inputs1: [
        {
          name: 'Tipo de doc:',
          type: 'select',
          options:
          [
            { text: 'NIT', value: 'NIT' },
            { text: 'CC', value: 'CC' }
          ],
          dataModel: 'identificationObjectType'
        },
        { name: 'Documento', type: 'number', dataModel: 'identificationObjectNumber', iconClass: 'fas fa-id-card', rules: 'required||numeric' },
        { name: 'DV', type: 'number', dataModel: 'identificationObjectDv', iconClass: 'fas fa-check' },
        { name: 'ID Alegra', type: 'number', dataModel: 'id', iconClass: 'ri-bookmark-2-line' },
        { name: 'Nombre', type: 'text', dataModel: 'name', iconClass: 'fas fa-user-shield', rules: 'required' },
        { name: 'Nombre del Administrador', type: 'text', dataModel: 'nameAdmin', iconClass: 'fas fa-user' },
        { name: 'Email', type: 'text', dataModel: 'email', iconClass: 'fas fa-at', rules: 'required||email' },
        { name: 'Numero de Contacto', type: 'number', dataModel: 'phonePrimary', iconClass: 'fas fa-phone-alt', rules: 'required||numeric' },
        { name: 'Pais', type: 'text', dataModel: 'addressCountry', iconClass: 'fas fa-globe-americas', rules: 'required' },
        { name: 'Departamento', type: 'text', dataModel: 'addressDepartment', iconClass: 'fas fa-flag', rules: 'required' },
        { name: 'Ciudad', type: 'text', dataModel: 'addressCity', iconClass: 'far fa-flag', rules: 'required' },
        { name: 'Direccion', type: 'text', dataModel: 'addressAddress', iconClass: 'fas fa-map-marker-alt', rules: 'required' },
        { name: 'Barrio', type: 'text', dataModel: 'addressNeighborhood', iconClass: 'fas fa-home' },
        { name: 'Sector', type: 'text', dataModel: 'addressSector', iconClass: 'ri-bookmark-2-line' }
      ],
      inputs2: [
        { name: 'Usuarios', type: 'number', dataModel: 'users', iconClass: 'fas fa-home' },
        { name: 'Empresa', type: 'text', dataModel: 'empresa', iconClass: 'fas fa-home' },
        { name: 'Nombre del Contacto', type: 'text', dataModel: 'nombreContacto', iconClass: 'fas fa-home' },
        { name: 'Celular del Contacto', type: 'text', dataModel: 'celularContacto', iconClass: 'ri-bookmark-2-line' },
        { name: 'Valor Total', type: 'number', dataModel: 'valorTotal', iconClass: 'fas fa-id-card' },
        { name: 'Valor Anticipo', type: 'number', dataModel: 'valorAnticipo', iconClass: 'fas fa-check' },
        {
          name: 'Estado del Pago',
          type: 'select',
          options:
          [
            { text: 'Debe', value: 'Debe' },
            { text: 'Anticipo', value: 'Anticipo' },
            { text: 'Pago', value: 'Pago' }
          ],
          dataModel: 'estadoPago'
        },
        { name: '# Factura:', type: 'text', dataModel: 'Factura', iconClass: 'fas fa-user' }
      ],
      inputs3: [
        { name: 'Fecha del Evento', type: 'calendar', dataModel: 'fechaEvento', iconClass: 'fas fa-id-card', rules: 'required' },
        { name: 'Hora del Evento', type: 'hour', dataModel: 'horaEvento', iconClass: 'fas fa-id-card', rules: 'required' },
        {
          name: 'Agendado por',
          type: 'select',
          options:
          [
            { text: 'Diana V', value: 'Diana V' },
            { text: 'Maria C', value: 'Maria C' },
            { text: 'Andrea G', value: 'Andrea G' },
            { text: 'Valentina C', value: 'Valentina C' },
            { text: 'Viviana R', value: 'Viviana R' },
            { text: 'Yuliana', value: 'Yuliana' },
            { text: 'Juliana O', value: 'Juliana O' },
            { text: 'Santiago O', value: 'Santiago O' }
          ],
          dataModel: 'agendadoPor'
        },
        { name: 'Observaciones', type: 'text', dataModel: 'observaciones', iconClass: 'fas fa-id-card' },
        { name: 'Georeferenciacion', type: 'text', dataModel: 'location', iconClass: 'fas fa-id-card' },
        { name: 'Direccion del Evento', type: 'text', dataModel: 'direccion', iconClass: 'fas fa-id-card' },
        { name: 'Telefono Lugar del Evento', type: 'text', dataModel: 'telefonoporteria', iconClass: 'fas fa-id-card' },
        {
          name: 'Conductor',
          type: 'select',
          options: [],
          dataModel: 'conductor'
        },
        {
          name: 'Modalidad',
          type: 'select',
          options:
          [
            { text: 'Presencial', value: 'presencial' },
            { text: 'Virtual', value: 'virtual' },
            { text: 'Mixta', value: 'mixta' },
            { text: 'Cotización', value: 'cotizacion' }
          ],
          dataModel: 'modalidad'
        },
        {
          name: 'Canal',
          type: 'select',
          options:
          [
            { text: 'Internet', value: 'internet' },
            { text: 'Cliente', value: 'cliente' },
            { text: 'Referido', value: 'referido' }
          ],
          dataModel: 'canal'
        },
        { name: 'Base de Datos', type: 'text', dataModel: 'basededatos', iconClass: 'fas fa-home' },
        { name: 'Sala', type: 'text', dataModel: 'sala', iconClass: 'fas fa-id-card' },
        { name: 'Usuario Zoom', type: 'text', dataModel: 'usuarioZoom', iconClass: 'fas fa-id-card' },
        { name: 'Link Reunion', type: 'text', dataModel: 'linkReunion', iconClass: 'fas fa-id-card' },
        { name: 'Descarga Informes', type: 'text', dataModel: 'descargaInformes', iconClass: 'fas fa-id-card' },
        { name: 'Informes', type: 'text', dataModel: 'informes', iconClass: 'fas fa-id-card' }
      ],
      modalShow: false,
      crudType: '',
      dataToModal: {},
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      // Data para el tab de logística
      groupDisplayType: null,
      filtroFecha: '',
      fechaFiltrada: [],
      videobeam: 0,
      bafles: 0,
      fichosQr: 0,
      computadores: 0,
      sonidosCompletos: 0,
      conexiones: 0,
      controles: 0,
      votacionElectronica: 0,
      pantalla: 0
    }
  },
  beforeMount () {
    this.gridOptions = {}
    this.AssembliesHeaders = [
      { headerName: 'Id', field: 'id', sortable: true, filter: true, checkboxSelection: true, width: 130 },
      { headerName: 'Nombre', field: 'name', sortable: true, filter: true, width: 255 },
      { headerName: 'Nit', field: 'nit', sortable: true, filter: true, width: 130 },
      { headerName: 'Fecha', field: 'date', sortable: true, filter: true, width: 120, sort: 'asc' },
      { headerName: 'Hora', field: 'hour', sortable: true, filter: true, width: 110 },
      { headerName: 'Admin', field: 'admin', sortable: true, filter: true, width: 130 },
      { headerName: 'Contrato', field: 'contrato', sortable: true, filter: true, width: 117 },
      { headerName: 'Empresa', field: 'empresa', sortable: true, filter: true },
      { headerName: 'Contacto', field: 'nombreContacto', sortable: true, filter: true },
      { headerName: 'Celular', field: 'celularContacto', sortable: true, filter: true, width: 120 },
      { headerName: 'Usuarios', field: 'users', sortable: true, filter: true, width: 110 },
      { headerName: 'Cotizacion', field: 'cotizacion', sortable: true, filter: true, width: 110 },
      { headerName: 'Valor Total', field: 'valorTotal', sortable: true, filter: true, width: 115 },
      { headerName: 'Anticipo', field: 'anticipo', sortable: true, filter: true, width: 115 },
      { headerName: 'Agendado Por', field: 'creator.nombre', sortable: true, filter: true },
      { headerName: 'Modalidad', field: 'modalidad', sortable: true, filter: true, width: 110 },
      { headerName: 'Maps', field: 'location', sortable: true, filter: true },
      { headerName: 'Direccion', field: 'direccion', sortable: true, filter: true },
      { headerName: 'Telefono Porteria', field: 'telefonoporteria', sortable: true, filter: true },
      { headerName: 'Canal', field: 'canal', sortable: true, filter: true, width: 110 },
      { headerName: 'Base de Datos', field: 'basededatos', sortable: true, filter: true },
      { headerName: 'Sala', field: 'sala', sortable: true, filter: true },
      { headerName: 'Usuario Zoom', field: 'usuarioZoom', sortable: true, filter: true },
      { headerName: 'Link de Reunion', field: 'linkReunion', sortable: true, filter: true },
      { headerName: 'Prestador de Servicio', field: 'attendees.nombre', sortable: true, filter: true },
      { headerName: 'Descarga de Informes', field: 'descargaInformes', sortable: true, filter: true },
      { headerName: 'Estado de Pago', field: 'estadoPago', sortable: true, filter: true },
      { headerName: 'Informes', field: 'informes', sortable: true, filter: true }
    ]
    this.filtrosLogistica = [
      { headerName: 'Item', field: 'item', sortable: true, filter: true, width: 350 },
      { headerName: 'Cantidad', field: 'cantidad', sortable: true, filter: true, width: 150 },
      { headerName: 'Observaciones', field: 'observaciones', sortable: true, filter: true, width: 500 },
      { headerName: 'Unidad', field: 'unidad', sortable: true, filter: true, width: 400 }
    ]
  },
  mounted () {
    core.index()
    db.collection('campus/Vs2FkUx38u3W8IDuABNF/assemblies2022Index')
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.empty) {
          const vm = this
          const passArray = []
          this.scheduleList = []
          this.calendarData = []
          this.undefinedList = []
          this.cancelledList = []
          querySnapshot.forEach(doc => {
            // console.log('Cada documento de asambleas', doc.data())
            const data = doc.data()
            Object.values(data).forEach(function (value) {
              if (value.date === '-' && value.modalidad !== 'cancelado') {
                vm.undefinedList.push(value)
                // console.log('INDEFINIDO', value)
              }
              if (value.modalidad !== 'cancelado') {
                // console.log('es diferente')
                vm.scheduleList.push(value)
              }
              if (value.modalidad === 'cancelado') {
                vm.cancelledList.push(value)
              }
              passArray.push({
                id: value.id,
                name: value.name,
                paymentStatus: value.paymentStatus,
                date: value.date,
                hour: value.hour,
                users: value.users,
                admin: value.admin,
                boardId: value.boardId,
                type: value.modalidad,
                observation: value.memo,
                idCotizacion: value.idCotizacion
              })
            })
          })
          vm.calendarData = passArray.sort(function (a, b) {
            if (a.hour > b.hour) return 1
            if (a.hour < b.hour) return -1
            return 0
          })
        }
      })
    this.gridApi = this.gridOptions.api
    this.gridColumnApi = this.gridOptions.columnApi
  },
  computed: {
  },
  watch: {
    tabActive () {
      this.deleteBtn = false
    }
  },
  methods: {
    makeToast (variant = null, message) {
      var title1 = ''
      if (variant === 'success') {
        title1 = 'Proceso exitoso'
      } else if (variant === 'danger') {
        title1 = 'Proceso fallido'
      }
      this.$bvToast.toast(message, {
        title: title1,
        variant: variant,
        solid: true,
        autoHideDelay: 10000
      })
    },
    loadSuccess (type) {
      this.overlay = type.type
    },
    onAssemblieSelected (event) {
      if (!event.node.selected) {
        return
      }
      console.log(event.node.data)
      this.assemblieSelected = event.node.data
      this.deleteBtn = true
    },
    onSelectionChanged (event) {
      var rowCount = event.api.getSelectedNodes().length
      console.log(rowCount)
      if (rowCount >= 1) {
        this.deleteBtn = true
      } else {
        this.deleteBtn = false
        this.assemblieSelected = null
      }
    },
    clearModal () {
      console.log('limpiando')
      this.crudType = ''
      this.data = {}
      this.oldData = {}
      console.log('lo modifique en clearModal')
      this.dataToModal = {}
      this.contactsModel = {
        address: {
          address: '',
          city: '',
          country: '',
          department: '',
          neighborhood: '',
          sector: ''
        },
        type: '',
        name: '',
        nameAdmin: '',
        email: '',
        phonePrimary: '',
        id: '',
        identificationObject: {
          number: '',
          type: '',
          dv: ''
        }
      }
      this.modalShow = false
    },
    createOrder () {
      this.crudType = 'create'
      this.modalShow = true
    },
    async onRowClicked2 (params) {
      console.log(params.node)
      this.rowNode = params.node.id
      console.log('lo modifique en onRowClicked2')
      if (params.node.data.endHour === undefined) {
        params.node.data.endHour = '-'
      }
      if (params.node.data.endDate === undefined) {
        params.node.data.endDate = '-'
      }
      this.dataToModal = params.node.data
      this.crudType = 'update'
      this.modalShow = true
    },
    async editFromCalendar (element) {
      for (let index = 0; index < this.scheduleList.length; index++) {
        if (this.scheduleList[index].id === element.params.id) {
          console.log('lo modifique en editFromCalendar', this.scheduleList[index])
          if (this.scheduleList[index].endHour === undefined) {
            this.scheduleList[index].endHour = '-'
          }
          if (this.scheduleList[index].endDate === undefined) {
            this.scheduleList[index].endDate = '-'
          }
          this.dataToModal = await this.scheduleList[index]
        }
      }
      this.crudType = 'update'
      this.modalShow = true
    },
    deleteAssemblie () {
      const vm = this
      vm.assemblieSelected.modalidad = 'cancelado'
      db.collection('campus/Vs2FkUx38u3W8IDuABNF/assemblies2022Index')
        .doc('assembliesIndex1')
        .set({
          [vm.assemblieSelected.id]: vm.assemblieSelected
        }, { merge: true })
      this.deleteBtn = false
    },
    sizeToFit () {
      this.gridApi.sizeColumnsToFit()
    },
    autoSizeAll (skipHeader) {
      console.log('ajustando')
      var allColumnIds = []
      this.gridColumnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId)
      })
      this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
    },
    async itemsFechas (idAlegra) {
      await axios({
        method: 'get',
        url: 'https://api.alegra.com/api/v1/estimates/' + idAlegra,
        headers: { Authorization: basicAuth }
      }).then(res => {
        console.log(res)
        for (let index = 0; index < res.data.items.length; index++) {
          this.fechaFiltrada.push({
            unidad: res.data.client.name,
            item: res.data.items[index].name,
            cantidad: res.data.items[index].quantity,
            observaciones: res.data.items[index].description
          })
          var extraerFichos = ''
          var fichos = ''
          switch (res.data.items[index].name) {
            case 'Alquiler de Videobeam':
              this.videobeam += 1
              break
            case 'Sonido Completo':
              this.videobeam += 1
              this.bafles += 1
              this.computadores += 1
              this.sonidosCompletos += 1
              this.pantalla += 1
              break
            case 'Bafle con Micrófono':
              this.bafles += 1
              break
            case 'Conexión de Asambleas':
              this.conexiones += 1
              break
            case 'Controles de votación electrónica':
              this.controles += parseInt(res.data.items[index].quantity, 10)
              this.votacionElectronica += 1
              break
            case 'Fichos QR':
              this.fichosQr += parseInt(res.data.items[index].quantity, 10)
              break
            case 'Sistema de votación  electrónica ':
              this.computadores += 1
              this.votacionElectronica += 1
              extraerFichos = res.data.items[index].description
              fichos = extraerFichos.split(' ')
              console.log('fichos', fichos[1])
              if (parseInt(fichos[1], 10) > 0) {
                this.fichosQr += parseInt(fichos[1], 10)
              }
              break
            case 'VideoBeam con pantalla':
              this.pantalla += 1
              this.videobeam += 1
              break
          }
        }
      }).catch((error) => {
        this.makeToast('danger', 'Contacto no traido de Alegra ' + error.response)
        console.log('Contacto no traido de Alegra ' + error)
      })
    },
    llenarFecha () {
      this.fechaFiltrada = []
      this.videobeam = 0
      this.bafles = 0
      this.fichosQr = 0
      this.computadores = 0
      this.sonidosCompletos = 0
      this.conexiones = 0
      this.controles = 0
      this.votacionElectronica = 0
      this.pantalla = 0
      console.log('datos especificos', this.calendarData)
      const filtroFecha = this.calendarData.filter(passArrays => passArrays.date === this.filtroFecha)
      console.log('filtroFecha', filtroFecha)
      const filtroType = filtroFecha.filter(filtroFechas => filtroFechas.type === 'presencial' || filtroFechas.type === 'mixta')
      console.log('filtroType', filtroType)
      console.log('prueba juan', filtroType[0].idCotizacion)
      for (let index = 0; index < filtroType.length; index++) {
        this.itemsFechas(filtroType[index].idCotizacion)
      }
    }
  }
}
</script>
<style>
.ag-header-cell.ag-header-cell-sortable {
    background-color: #142b73;
    color: white;
}
.ag-theme-material .ag-header-group-cell:not(.ag-column-resizing) + .ag-header-group-cell:hover, .ag-theme-material .ag-header-group-cell:not(.ag-column-resizing) + .ag-header-group-cell.ag-column-resizing, .ag-theme-material .ag-header-cell:not(.ag-column-resizing) + .ag-header-cell:hover, .ag-theme-material .ag-header-cell:not(.ag-column-resizing) + .ag-header-cell.ag-column-resizing, .ag-theme-material .ag-header-group-cell:first-of-type:hover, .ag-theme-material .ag-header-group-cell:first-of-type.ag-column-resizing, .ag-theme-material .ag-header-cell:first-of-type:hover, .ag-theme-material .ag-header-cell:first-of-type.ag-column-resizing {
    color: #292828;
}
.ag-header-group-cell.ag-header-group-cell-with-group{
  background-color: #edf1fb;
}
</style>
