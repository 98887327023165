var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-modal',{attrs:{"title":_vm.type === 1 ? 'Create meeting': 'Updated meeting',"size":"lg"},on:{"change":_vm.changeModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [(_vm.type===1)?_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"float-right",attrs:{"disabled":_vm.initTransaction,"variant":"primary","size":"sm"},on:{"click":_vm.createMeeting}},[_vm._v(" Create Meeting ")]),_c('b-button',{staticClass:"float-right mr-2",attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.closeModal}},[_vm._v(" Close ")])],1):_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"float-right",attrs:{"disabled":_vm.initTransaction,"variant":"primary","size":"sm"},on:{"click":_vm.updateMeeting}},[_vm._v(" Update Meeting ")]),_c('b-button',{staticClass:"float-right mr-2",attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.closeModal}},[_vm._v(" Close ")])],1)]},proxy:true}]),model:{value:(_vm.showModalChild),callback:function ($$v) {_vm.showModalChild=$$v},expression:"showModalChild"}},[(_vm.initTransaction)?_c('div',[_c('loader-process',{attrs:{"message":_vm.messageInformation}})],1):_c('div',[_c('ValidationObserver',{ref:"observer"},[_c('form',[_c('b-row',{staticClass:"file-field"},[_c('b-col',{staticClass:"mb-5",attrs:{"cols":"8"}},[_c('label',{staticClass:"ml-2",attrs:{"for":"input-small"}},[_vm._v("Topic Room ")]),_c('ValidationProvider',{attrs:{"name":"Topic Room","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"size":"sm"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',[_c('i',{staticClass:"fas fa-video"})])]},proxy:true}],null,true)},[_c('b-form-input',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"text","id":"meetingName","placeholder":"Enter the name of room"},model:{value:(_vm.meeting.topic),callback:function ($$v) {_vm.$set(_vm.meeting, "topic", $$v)},expression:"meeting.topic"}}),_c('div',{staticClass:"invalid-feedback ml-2"},[_c('span',[_vm._v(_vm._s(errors[0]))])])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-5",attrs:{"cols":"12"}},[_c('label',{staticClass:"ml-2",attrs:{"for":"input-small"}},[_vm._v("Description (optional)")]),_c('ValidationProvider',{attrs:{"name":"Description Room","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"size":"sm"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',[_c('i',{staticClass:"fas fa-pen-square"})])]},proxy:true}],null,true)},[_c('b-form-input',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"text","id":"meetingDescription","placeholder":"Enter the name of room"},model:{value:(_vm.descriptionMeeting),callback:function ($$v) {_vm.descriptionMeeting=$$v},expression:"descriptionMeeting"}}),_c('div',{staticClass:"invalid-feedback ml-2"},[_c('span',[_vm._v(_vm._s(errors[0]))])])],1)]}}])})],1),_c('b-col',{staticClass:"mb-5",attrs:{"cols":"6"}},[_c('label',{staticClass:"ml-2",attrs:{"for":"input-small"}},[_vm._v("Date picker ")]),_c('ValidationProvider',{attrs:{"name":"Date  picker","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"size":"sm"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',[_c('i',{staticClass:"far fa-calendar-check"})])]},proxy:true}],null,true)},[_c('b-form-datepicker',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"locale":"es","min":_vm.min,"id":"meetingStartTime"},model:{value:(_vm.start_time),callback:function ($$v) {_vm.start_time=$$v},expression:"start_time"}}),_c('div',{staticClass:"invalid-feedback ml-2"},[_c('span',[_vm._v(_vm._s(errors[0]))])])],1)]}}])})],1),_c('b-col',{staticClass:"mb-5",attrs:{"cols":"6","sm":"3"}},[_c('label',{staticClass:"ml-2",attrs:{"for":"input-small"}},[_vm._v("Start time ")]),_c('ValidationProvider',{attrs:{"name":"Start time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-select',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"options":_vm.selectTimeInit,"id":"meetingTimeInit"},model:{value:(_vm.time_init),callback:function ($$v) {_vm.time_init=$$v},expression:"time_init"}}),_c('div',{staticClass:"invalid-feedback ml-2"},[_c('span',[_vm._v(_vm._s(errors[0]))])])],1)]}}])})],1),_c('b-col',{staticClass:"mb-5",attrs:{"cols":"6","sm":"3"}},[_c('label',{staticClass:"ml-2",attrs:{"for":"input-small"}},[_vm._v("Time Init ")]),_c('ValidationProvider',{attrs:{"name":"Time init","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-select',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"options":_vm.selectTime,"id":"meetingTime"},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}}),_c('div',{staticClass:"invalid-feedback ml-2"},[_c('span',[_vm._v(_vm._s(errors[0]))])])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-5",attrs:{"cols":"12","md":"3"}},[_c('label',{staticClass:"ml-2",attrs:{"for":"input-small"}},[_vm._v("Duration hours ")]),_c('ValidationProvider',{attrs:{"name":"Hours Duration Room","rules":"required|min_value:60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"size":"sm"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',[_c('i',{staticClass:"ri-timer-line"})])]},proxy:true}],null,true)},[_c('b-form-select',{class:(errors.length > 0 ? ' is-invalid' : ''),staticStyle:{},attrs:{"options":_vm.selectHoursDuration,"id":"meetingTime"},model:{value:(_vm.hoursDuration),callback:function ($$v) {_vm.hoursDuration=$$v},expression:"hoursDuration"}}),_c('p',{staticClass:"ml-3"},[_vm._v("h")]),_c('div',{staticClass:"invalid-feedback ml-2"},[_c('span',[_vm._v(_vm._s(errors[0]))])])],1)]}}])})],1),_c('b-col',{staticClass:"mb-5",attrs:{"cols":"12","md":"3"}},[_c('label',{staticClass:"ml-2",attrs:{"for":"input-small"}},[_vm._v(" Minutes ")]),_c('ValidationProvider',{attrs:{"name":"Minutes Room","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-select',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"options":_vm.selectMinutesDuration,"id":"meetingTime"},model:{value:(_vm.minutesDuration),callback:function ($$v) {_vm.minutesDuration=$$v},expression:"minutesDuration"}}),_c('p',{staticClass:" ml-3"},[_vm._v("m")]),_c('div',{staticClass:"invalid-feedback ml-2"},[_c('span',[_vm._v(_vm._s(errors[0]))])])],1)]}}])})],1)],1)],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }