<template>
  <b-container fluid="">
    <b-modal :title="type === 1 ? 'Create meeting': 'Updated meeting'" size="lg" v-model="showModalChild" @change="changeModal">
        <div v-if="initTransaction">
            <loader-process :message="messageInformation"></loader-process>
        </div>
        <div v-else>
            <ValidationObserver ref="observer">
            <form>
                <b-row class="file-field">
                <b-col cols="8" class="mb-5">
                    <label class="ml-2" for="input-small">Topic Room </label>
                    <ValidationProvider name="Topic Room" rules="required" v-slot="{ errors }" >
                    <b-input-group size="sm" >
                    <template #prepend>
                        <b-input-group-text >
                        <i class="fas fa-video"></i>
                        </b-input-group-text>
                    </template>
                    <b-form-input v-model="meeting.topic" type="text" id="meetingName" placeholder="Enter the name of room" :class="(errors.length > 0 ? ' is-invalid' : '')">
                    </b-form-input>
                    <div class="invalid-feedback ml-2">
                        <span>{{ errors[0] }}</span>
                    </div>
                    </b-input-group>
                    </ValidationProvider>
                </b-col>
                </b-row>
                <b-row>
                <b-col cols="12" class="mb-5">
                    <label class="ml-2" for="input-small">Description (optional)</label>
                    <ValidationProvider name="Description Room" rules="" v-slot="{ errors }" >
                    <b-input-group size="sm" >
                    <template #prepend>
                        <b-input-group-text >
                        <i class="fas fa-pen-square"></i>
                        </b-input-group-text>
                    </template>
                    <b-form-input v-model="descriptionMeeting" type="text" id="meetingDescription" placeholder="Enter the name of room" :class="(errors.length > 0 ? ' is-invalid' : '')">
                    </b-form-input>
                    <div class="invalid-feedback ml-2">
                        <span>{{ errors[0] }}</span>
                    </div>
                    </b-input-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="6" class="mb-5">
                    <label class="ml-2" for="input-small">Date picker </label>
                    <ValidationProvider name="Date  picker" rules="required" v-slot="{ errors }" >
                    <b-input-group size="sm" >
                    <template #prepend>
                        <b-input-group-text >
                        <i class="far fa-calendar-check"></i>
                        </b-input-group-text>
                    </template>
                    <b-form-datepicker v-model="start_time" locale="es" :min="min" id="meetingStartTime" :class="(errors.length > 0 ? ' is-invalid' : '')">
                    </b-form-datepicker>
                    <div class="invalid-feedback ml-2">
                        <span>{{ errors[0] }}</span>
                    </div>
                    </b-input-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="6" sm="3" class="mb-5">
                    <label class="ml-2" for="input-small">Start time </label>
                    <ValidationProvider name="Start time" rules="required" v-slot="{ errors }" >
                    <b-input-group size="sm" >
                    <b-form-select v-model="time_init" :options="selectTimeInit" id="meetingTimeInit" :class="(errors.length > 0 ? ' is-invalid' : '')">
                    </b-form-select>
                    <div class="invalid-feedback ml-2">
                        <span>{{ errors[0] }}</span>
                    </div>
                    </b-input-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="6" sm="3" class="mb-5">
                    <label class="ml-2" for="input-small">Time Init </label>
                    <ValidationProvider name="Time init" rules="required" v-slot="{ errors }" >
                    <b-input-group size="sm" >
                    <b-form-select v-model="time" :options="selectTime" id="meetingTime" :class="(errors.length > 0 ? ' is-invalid' : '')">
                    </b-form-select>
                    <div class="invalid-feedback ml-2">
                        <span>{{ errors[0] }}</span>
                    </div>
                    </b-input-group>
                    </ValidationProvider>
                </b-col>
                </b-row>
                <b-row>
                <b-col cols="12" md="3" class="mb-5">
                    <label class="ml-2" for="input-small">Duration hours </label>
                    <ValidationProvider name="Hours Duration Room" rules="required|min_value:60" v-slot="{ errors }" >
                    <b-input-group size="sm" >
                    <template #prepend>
                        <b-input-group-text >
                        <i class="ri-timer-line"></i>
                        </b-input-group-text>
                    </template>
                    <b-form-select style="80px" v-model="hoursDuration" :options="selectHoursDuration" id="meetingTime" :class="(errors.length > 0 ? ' is-invalid' : '')">
                    </b-form-select>
                    <p class="ml-3">h</p>
                    <div class="invalid-feedback ml-2">
                        <span>{{ errors[0] }}</span>
                    </div>
                    </b-input-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="3" class="mb-5">
                    <label class="ml-2" for="input-small"> Minutes </label>
                    <ValidationProvider name="Minutes Room" rules="required" v-slot="{ errors }" >
                    <b-input-group size="sm" >
                    <b-form-select v-model="minutesDuration" :options="selectMinutesDuration" id="meetingTime" :class="(errors.length > 0 ? ' is-invalid' : '')">
                    </b-form-select>
                    <p class=" ml-3">m</p>
                    <div class="invalid-feedback ml-2">
                        <span>{{ errors[0] }}</span>
                    </div>
                    </b-input-group>
                    </ValidationProvider>
                </b-col>
                </b-row>
            </form>
            </ValidationObserver>
        </div>
        <template #modal-footer >
            <div class="w-100" v-if="type===1">
              <b-button
                  :disabled="initTransaction"
                  variant="primary"
                  size="sm"
                  class="float-right"
                  @click="createMeeting"
              >
                  Create Meeting
              </b-button>
              <b-button
                variant="primary"
                size="sm"
                class="float-right mr-2"
                @click="closeModal"
              >
                Close
              </b-button>
            </div>
            <div class="w-100" v-else>
              <b-button
                  :disabled="initTransaction"
                  variant="primary"
                  size="sm"
                  class="float-right"
                  @click="updateMeeting"
              >
                  Update Meeting
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mr-2"
                  @click="closeModal"
              >
                  Close
              </b-button>
            </div>
        </template>
    </b-modal>
  </b-container>
</template>
<script>
import LoaderProcess from '@/components/core/loader/LoaderProcess.vue'
import axios from 'axios'
import { db } from '@/config/firebase'
axios.defaults.baseURL = process.env.VUE_APP_URL_BASE_CLOUD_FUNCTIONS_API

export default {
  name: 'ModalMeeting',
  components: {
    LoaderProcess
  },
  props: {
    showModal: { type: Boolean, default: false },
    type: { type: Number, default: 1 },
    meetingUpdate: { type: Object, default: () => {} },
    // inicio cambios salas sistevot
    queryDataZoom: { type: Object, default: () => {} }
    // fin cambios salas sistevot
  },
  watch: {
    showModal (newVal) {
      this.showModalChild = newVal
    },
    meetingUpdate (newVal) {
      if (Object.keys(newVal).length !== 0) {
        this.idMeeting = newVal.idMeeting
        this.docIdMeeting = newVal.id
        this.meeting.topic = newVal.topic
        this.descriptionMeeting = newVal.description
        this.hoursDuration = (parseInt(newVal.duration / 60)) * 60
        this.minutesDuration = newVal.duration - this.hoursDuration
        const dateSplit = newVal.startTime.split('T')
        this.start_time = dateSplit[0]
        const startTimeSplit = dateSplit[1].split(':')
        if (parseInt(startTimeSplit[0]) >= 12) {
          const hourInit = parseInt(startTimeSplit[0]) - 12
          this.time_init = `${hourInit < 10 ? '0' + hourInit : hourInit}:${startTimeSplit[1]}`
          this.time = 'PM'
        } else {
          this.time_init = `${startTimeSplit[0]}:${startTimeSplit[1]}`
          this.time = 'AM'
        }
      } else {
        this.idMeeting = ''
        this.docIdMeeting = ''
        this.descriptionMeeting = ''
        this.hoursDuration = 0
        this.minutesDuration = 0
        this.start_time = ''
        this.time_init = ''
      }
    }
  },
  async mounted () {
    // inicio cambios salas sistevot
    this.convertir_hora()
    // fin cambios salas sistevot
  },
  data () {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minDate = new Date(today)
    minDate.setMonth(minDate.getMonth() - 1)
    minDate.setDate(15)
    return {
      showModalChild: this.showModal,
      idMeeting: '',
      docIdMeeting: '',
      messageInformation: '',
      dataMeetings: null,
      // inicio cambios salas sistevot
      start_time: this.queryDataZoom.date,
      time_init: '',
      time: 'AM',
      min: minDate,
      hoursDuration: 600,
      minutesDuration: 0,
      descriptionMeeting: this.queryDataZoom.sala,
      meeting: {
        topic: 'Asamblea ' + this.queryDataZoom.name,
        type: 2,
        start_time: this.queryDataZoom.date,
        duration: 10,
        // fin cambios salas sistevot
        schedule_for: '',
        timezone: 'America/Bogota',
        password: '',
        agenda: '',
        settings: {
          host_video: false,
          participant_video: false,
          cn_meeting: false,
          in_meeting: false,
          join_before_host: true,
          jbn_time: 0,
          mute_upon_entry: true,
          watermark: false,
          use_pmi: false,
          approval_type: 2,
          audio: 'voip',
          auto_recording: 'cloud',
          enforce_login: false,
          enforce_login_domains: false,
          alternative_hosts: '',
          global_dial_in_countries: [
            ''
          ],
          registrants_email_notification: false
        }
      },
      meetingDefault: {
        topic: '',
        type: 2,
        start_time: '',
        duration: 0,
        schedule_for: '',
        timezone: 'America/Bogota',
        password: '',
        agenda: '',
        settings: {
          host_video: false,
          participant_video: false,
          cn_meeting: false,
          in_meeting: false,
          join_before_host: true,
          jbn_time: 0,
          mute_upon_entry: false,
          watermark: false,
          use_pmi: false,
          approval_type: 2,
          meeting_authentication: false,
          audio: 'voip',
          auto_recording: 'cloud',
          enforce_login: false,
          enforce_login_domains: false,
          alternative_hosts: '',
          global_dial_in_countries: [
            ''
          ],
          registrants_email_notification: false
        }
      },
      initTransaction: false,
      selectTimeInit: [
        { value: '00:00', text: '12:00' },
        { value: '00:30', text: '12:30' },
        { value: '12:00', text: '12:00' },
        { value: '12:30', text: '12:30' },
        { value: '01:00', text: '01:00' },
        { value: '01:30', text: '01:30' },
        { value: '02:00', text: '02:00' },
        { value: '02:30', text: '02:30' },
        { value: '03:00', text: '03:00' },
        { value: '03:30', text: '03:30' },
        { value: '04:00', text: '04:00' },
        { value: '04:30', text: '04:30' },
        { value: '05:00', text: '05:00' },
        { value: '05:30', text: '05:30' },
        { value: '06:00', text: '06:00' },
        { value: '06:30', text: '06:30' },
        { value: '07:00', text: '07:00' },
        { value: '07:30', text: '07:30' },
        { value: '08:00', text: '08:00' },
        { value: '08:30', text: '08:30' },
        { value: '09:00', text: '09:00' },
        { value: '09:30', text: '09:30' },
        { value: '10:00', text: '10:00' },
        { value: '10:30', text: '10:30' },
        { value: '11:00', text: '11:00' },
        { value: '11:30', text: '11:30' }
      ],
      selectHoursDuration: [
        { value: 0, text: '0' },
        { value: 60, text: '1' },
        { value: 120, text: '2' },
        { value: 180, text: '3' },
        { value: 240, text: '4' },
        { value: 300, text: '5' },
        { value: 360, text: '6' },
        { value: 420, text: '7' },
        { value: 480, text: '8' },
        { value: 540, text: '9' },
        { value: 600, text: '10' },
        { value: 660, text: '11' },
        { value: 720, text: '12' },
        { value: 780, text: '13' },
        { value: 840, text: '14' },
        { value: 900, text: '15' },
        { value: 960, text: '16' },
        { value: 1020, text: '17' },
        { value: 1080, text: '18' },
        { value: 1140, text: '19' },
        { value: 1200, text: '20' },
        { value: 1260, text: '21' },
        { value: 1320, text: '22' },
        { value: 1380, text: '23' },
        { value: 1440, text: '24' }
      ],
      selectMinutesDuration: [
        { value: 0, text: '0' },
        { value: 15, text: '15' },
        { value: 30, text: '30' },
        { value: 45, text: '45' }
      ],
      selectTime: [
        { value: 'AM', text: 'AM' },
        { value: 'PM', text: 'PM' }
      ]
    }
  },
  methods: {
    async createMeeting () {
      const validation = await this.$refs.observer.validate()
      if (validation) {
        try {
          this.messageInformation = 'Creando reunion'
          this.initTransaction = true
          const jsonData = {}
          let startTime = ''
          const hourSplit = this.time_init.split(':')
          if (this.time === 'AM') {
            startTime = `${this.start_time}T${hourSplit[0]}:${hourSplit[1]}:00`
          } else {
            if (parseInt(hourSplit[0]) === 12) {
              startTime = `${this.start_time}T00:${hourSplit[1]}:00`
            } else {
              startTime = `${this.start_time}T${parseInt(hourSplit[0]) + 12}:${hourSplit[1]}:00`
            }
          }
          const durationMeeting = this.hoursDuration + this.minutesDuration
          this.meeting.start_time = startTime
          this.meeting.duration = durationMeeting

          jsonData.meeting = this.meeting
          jsonData.userInfo = {
            userDocId: 'Vs2FkUx38u3W8IDuABNF',
            coPropertyDocId: 'Vs2FkUx38u3W8IDuABNF',
            descriptionMeeting: this.descriptionMeeting
          }
          const { data } = await axios.get('usersZoom')
          const meeting = await Promise.all(
            data.data.map(async (user) => {
              const data = await axios.get(`listMeetingUpcoming/${user.email}`)
              return { meeting: data.data.data, records: data.data.records, email: user.email }
            })
          )
          let userWithLicense = ''
          let cont = 0
          do {
            if (meeting[cont].email.includes('sistevot')) {
              if (meeting[cont].records === 0) {
                userWithLicense = meeting[cont].email
              } else {
                let isDateValid = true
                for (let i = 0; i < meeting[cont].meeting.length; i++) {
                  const fechaSplit = meeting[cont].meeting[i].start_time.split('T')
                  if (fechaSplit[0] === this.start_time) {
                    isDateValid = false
                  }
                }
                if (isDateValid) {
                  userWithLicense = meeting[cont].email
                }
              }
            }
            cont++
          } while (userWithLicense === '' && cont !== meeting.length - 1)
          if (userWithLicense !== '') {
            jsonData.emailZoomEnabled = userWithLicense
            await axios.post('meetings', jsonData)
              .then((result) => {
                console.log(result.data)
                this.$emit('dataZoom', { data: result.data })
                db.collection('campus/Vs2FkUx38u3W8IDuABNF/meetings')
                  .add(result.data)
                  .then((docRef) => {
                    console.log('exito', docRef)
                  })
                  .catch((error) => { console.error(`EL error es: ${error}`) }).finally()
              })
            this.initTransaction = false
            this.closeModal()
            this.$emit('showAlertMessage', { data: 'The meet  has been created successfully.', type: 'success' })
            console.log('despues de guardar la reunion en zoom', jsonData)
          } else {
            this.initTransaction = false
            this.closeModal()
            this.$emit('showAlertMessage', { data: 'Sorry, we have no licensed users available for this date. Communicate with the responsible area.', type: 'error' })
          }
        } catch (error) {
          console.log(error)
          this.initTransaction = false
          this.closeModal()
          this.$emit('showAlertMessage', { data: 'Has been occurred an error: ' + error, type: 'error' })
        }
      } else {
        this.$emit('showAlertMessage', { data: 'Please complete the fields required.', type: 'error' })
      }
    },
    async updateMeeting () {
      const validation = await this.$refs.observer.validate()
      if (validation) {
        try {
          this.messageInformation = 'Creando reunion'
          this.initTransaction = true
          const jsonData = {}
          let startTime = ''
          const hourSplit = this.time_init.split(':')
          if (this.time === 'AM') {
            startTime = `${this.start_time}T${hourSplit[0]}:${hourSplit[1]}:00`
          } else {
            if (parseInt(hourSplit[0]) === 12) {
              startTime = `${this.start_time}T00:${hourSplit[1]}:00`
            } else {
              startTime = `${this.start_time}T${parseInt(hourSplit[0]) + 12}:${hourSplit[1]}:00`
            }
          }
          const durationMeeting = this.hoursDuration + this.minutesDuration
          this.meeting.start_time = startTime
          this.meeting.duration = durationMeeting

          jsonData.meeting = this.meeting
          jsonData.userInfo = {
            userDocId: 'Vs2FkUx38u3W8IDuABNF',
            coPropertyDocId: 'Vs2FkUx38u3W8IDuABNF',
            descriptionMeeting: this.descriptionMeeting
          }
          await axios
            .patch(
              'meetings',
              jsonData,
              { params: { idMeeting: this.idMeeting, docIdMeeting: this.docIdMeeting } })
          this.initTransaction = false
          this.closeModal()
          this.$emit('showAlertMessage', { data: 'The meet  has been created successfully.', type: 'success' })
        } catch (error) {
          this.initTransaction = false
          this.closeModal()
          this.$emit('showAlertMessage', { data: 'Has been occurred an error: ' + error, type: 'error' })
        }
      } else {
        this.$emit('showAlertMessage', { data: 'Please complete the fields required.', type: 'error' })
      }
    },
    closeModal () {
      this.cleanModal()
      this.$emit('changeModal', { data: true })
    },
    changeModal () {
      this.cleanModal()
      this.$emit('changeModal', { data: true })
    },
    change (isVisible) {
      console.log(isVisible)
    },
    cleanModal () {
      this.start_time = ''
      this.time_init = ''
      this.hoursDuration = 0
      this.minutesDuration = 0
      this.descriptionMeeting = ''
      this.meeting = Object.assign({}, this.meetingDefault)
    },
    // inicio cambios salas sistevot
    convertir_hora () {
      if (this.queryDataZoom.hour >= '13:00:00') {
        const startHour = this.queryDataZoom.hour.split(':')
        var [hour, minute] = startHour
        var newhour = hour - 12
        if (newhour < 10) {
          console.log('falta cero', newhour)
          newhour = '0' + newhour
        }
        // var startDate = DateTime.local(parseInt(year), parseInt(month), parseInt(day), parseInt(hour), parseInt(minute), parseInt(second))
        this.time_init = newhour + ':' + minute
        console.log('hora para zoom', this.time_init)
        this.time = 'PM'
      } else {
        this.time_init = this.queryDataZoom.hour.slice(0, -3)
      }
    }
    // fin cambios salas sistevot
  }
}
</script>
